import React from "react";
import { render } from "react-dom";
import "./index.css";
import { App } from "./App";
import "./tailwind.css";
import { StyledEngineProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst={true}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</StyledEngineProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
