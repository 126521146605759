import React, { FC, ReactNode } from "react";
import { ReactComponent as Usability } from "../../assets/logos/SVGs/logo/usability.svg";
import { ReactComponent as Individual } from "../../assets/logos/SVGs/logo/individuals.svg";
import { ReactComponent as Infinity } from "../../assets/logos/SVGs/logo/infinity.svg";
import { ReactComponent as Laptop } from "../../assets/logos/SVGs/logo/laptop.svg";
import { ReactComponent as Rocket } from "../../assets/logos/SVGs/logo/rocket.svg";
import { ReactComponent as Security } from "../../assets/logos/SVGs/logo/security.svg";

const AdvantageElement: FC<AdvantageElementType> = ({
	title,
	icon,
	description = "We believe in crypto’s future, we believe that in ten years it will",
	direction,
}: AdvantageElementType) => {
	return (
		<div className={"m-5 flex flex-col gap-3 text-center"}>
			<div className={"flex h-20 items-center justify-center"}>{icon}</div>
			<p
				className={
					"select-none whitespace-nowrap font-semibold uppercase tracking-widest text-secondary"
				}
			>
				{title}
			</p>
			{description}
		</div>
	);
};

const ourAdvantages: AdvantageElementType[] = [
	{
		title: "free for individuals",
		icon: <Individual />,
		direction: "bottomLeft",
		description: "Sign up for a free account!",
	},
	{
		title: "simple",
		icon: <Usability />,
		direction: "down",
		description:
			"Toggle the currency you want to send/receive and forget about it",
	},
	{
		title: "Fast",
		icon: <Rocket />,
		direction: "bottomRight",
		description: "Never wait for settlement again",
	},
	{
		title: "flexible",
		icon: <Infinity />,
		direction: "topLeft",
		description: "Option of currency to transact in",
	},
	{
		title: "security",
		icon: <Security />,
		direction: "up",
		description:
			"We use industry grade security to ensure safe, reliable transactions",
	},
	{
		title: "24/7 support",
		icon: <Laptop />,
		direction: "topRight",
		description: "Need Help? Our team is here to assist you",
	},
];

export const Advantages: FC = () => {
	return (
		<div
			id={"advantage"}
			className={"flex scroll-mt-12 flex-col gap-14  pb-4 text-gray-700"}
		>
			<div
				className={
					" mt-5 flex items-center text-center justify-center  text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl"
				}
			>
				{/* Our&nbsp;<span className={"text-secondary"}>Advantage</span> */}
				Fast, Simple, and Secured Crypto Solutions
			</div>
			<div
				className={
					"md:grid-rows-auto container mx-auto grid max-w-5xl justify-items-center p-0 md:grid-cols-2 md:gap-y-8 md:gap-x-16 lg:grid-cols-3 lg:grid-rows-2 lg:gap-y-0 lg:gap-x-32"
				}
			>
				{ourAdvantages.map(({ title, icon, description, direction }, index) => (
					<AdvantageElement
						key={index}
						title={title}
						icon={icon}
						description={description}
						direction={direction}
					/>
				))}
			</div>
		</div>
	);
};

type AdvantageElementType = {
	title: string;
	description?: string;
	icon: ReactNode;
	direction:
		| "topLeft"
		| "topRight"
		| "bottomLeft"
		| "bottomRight"
		| "right"
		| "left"
		| "up"
		| "down";
};
