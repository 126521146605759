import React, { FC } from "react";

export const ComingSoonTag: FC<tagTypes> = ({ variant, classNames }) => (
	<span
		className={`flex items-center rounded-full ${
			variant === "PRIMARY" ? "bg-primary" : "bg-secondary/25 text-white"
		} px-2 py-0.5 text-xs font-medium ${classNames}`}
	>
		Coming Soon
	</span>
);
export const BetaTag: FC<tagTypes> = () => (
	<span
		className={
			"flex items-center rounded-full bg-primary px-2 py-0.5 text-xs font-medium"
		}
	>
		Coming Soon
	</span>
);

type tagTypes = { variant?: "PRIMARY" | "SECONDARY"; classNames?: string };
