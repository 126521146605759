import React, { FC } from "react";
import { About } from "./About";
import { Advantages } from "./Advantages";
import { Features } from "./Features";
import { Predictions } from "./Predictions";
import { useTitle } from "../../hooks/useTitle";
import { HeaderCarousel } from "./HeaderCarousel";

export const Landing: FC = () => {
	useTitle("Secured crypto payments and trading solutions");

	return (
		<>
			<section
				className={
					"header-img pt-16 h-screen bg-cover bg-center bg-no-repeat bg-blend-color-dodge"
				}
			>
				<HeaderCarousel />
			</section>
			<section className="bg-white">
				{/* <PopUpComponent /> */}
				<About />
				<Advantages />
				<Features />
				<Predictions />
			</section>
			{/*<Working /> */}
		</>
	);
};
