/* eslint-disable react/no-unescaped-entities */
import React from "react";
import CookieConsent from "react-cookie-consent";

export const CookieConsentBanner = () => {
	return (
		<CookieConsent
			location="bottom"
			buttonText="Accept All"
			cookieName="myCookie"
			style={{
				background: "white",
				color: "#333",
				borderRadius: "5px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				border: "1px solid #ddd",
				height: "240px",
				width: "400px",
				zIndex: "9999",
				marginBottom: "20px",
				marginLeft: "20px",
				padding: "0px",
				paddingLeft: "15px",
			}}
			buttonStyle={{
				backgroundColor: "#007bff",
				color: "#fff",
				borderRadius: "5px",
				marginTop: "0",
			}}
			declineButtonStyle={{
				backgroundColor: "white",
				color: "#007bff",
				borderRadius: "5px",
				border: "1px solid #007bff",
				marginTop: "0",
			}}
			enableDeclineButton={true}
			declineButtonText="Decline"
			contentClasses="mb-0"
			overlayClasses="mb-0"
		>
			<h2 style={{ fontSize: "1.2em", fontWeight: "bold" }}>
				We value your privacy
			</h2>
			<p style={{ marginBottom: "0px" }}>
				We use cookies to enhance your browsing experience and analyse our
				traffic. By clicking "Accept All" you consent to our use of cookies.
			</p>
		</CookieConsent>
	);
};
