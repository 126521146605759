import React, { FC, ReactNode } from "react";

export const Code: FC<CodeType> = ({ children }) => {
	return (
		<div
			className={
				"mt-3 overflow-hidden overflow-x-scroll rounded-lg border border-gray-500 bg-white text-color-text p-4 shadow-xl"
			}
		>
			<code className="overflow-x-scroll whitespace-nowrap text-xxs md:text-base">
				{children}
			</code>
		</div>
	);
};

type CodeType = {
	children: ReactNode;
};
