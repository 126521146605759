import React, { FC, useState } from "react";
import { ReactComponent as MainLogo } from "../assets/logos/SVGs/logo/MainLogo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Box,
	Button,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleDown,
	faAngleUp,
	faArrowTrendUp,
	faBars,
} from "@fortawesome/free-solid-svg-icons";
import { TAG_TYPE } from "../utils/enums";
import { ComingSoonTag } from "./general/Tags";
import { navigationData } from "../utils/routes";

export const NavBar: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [show, setShow] = useState(true);
	const path = location.pathname;

	const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
	const [openSection, setOpenSection] = useState<Record<string, boolean>>({});

	const navigateTo = (href?: string | undefined) => {
		if (href) navigate(href);
	};
	const handleBorder = (href?: string, subList?: Array<unknown>): string => {
		if (subList && (path == "/about" || path == "/team")) {
			return "border-b-2 border-gold";
		} else if (path == href) {
			return "border-b-2 border-gold";
		}
		return "";
	};

	return (
		<>
			<nav
				className={`top-0 z-50 w-full bg-[#2D0734] text-gold drop-shadow-xl font-medium pt-2 max-w-screen absolute`}
			>
				<div
					className={
						"mx-auto flex p-2 md:px-7 justify-between w-full max-w-screen"
					}
				>
					<a
						className={"flex cursor-pointer items-center gap-x-2"}
						onClick={(): void => {
							window.scrollTo(0, 0);
							navigate("/");
						}}
					>
						<MainLogo fill={"#ED9212"} width={40} height={40} />
						<span
							style={{ fontFamily: "GoodTiming" }}
							className="text-[#ED9212]"
						>
							CoinSpaze
						</span>
					</a>
					<div
						className={
							"flex select-none items-center gap-x-4 md:gap-x-8 text-[16px]"
						}
					>
						{navigationData.map(
							({ title, href, tag, subList, disabled = false }, index) => (
								<div
									key={title + href + index}
									className={`group relative hidden ${
										href && "cursor-pointer"
									} overflow-visible md:block`}
								>
									<a
										className={`${href && "cursor-pointer"} md:block ${
											disabled && "relative text-gray-500"
										}
										${handleBorder(href, subList)}
										`}
										onClick={(): void => {
											//href && navigate(href);
											navigateTo(href);
											setShow(false);
										}}
									>
										{title}
										{tag === TAG_TYPE.COMING_SOON && (
											<ComingSoonTag
												variant={"SECONDARY"}
												classNames={"absolute -top-4 -right-2"}
											/>
										)}
									</a>
									{subList && (
										<List
											className={
												"absolute z-50 flex hidden w-[200px] flex-col rounded-b-lg bg-highlight text-white drop-shadow-xl transition transition-all duration-500 ease-linear group-hover:block"
											}
										>
											{subList?.map(
												({ tag, href, title, disabled = false }, index) => (
													<ListItem
														key={title + href + index}
														disablePadding
														disableGutters
													>
														<ListItemButton
															disabled={disabled}
															onClick={(): void => {
																//href && navigate(href);
																navigateTo(href);
															}}
														>
															<ListItemText primary={title} />
															{tag === TAG_TYPE.COMING_SOON && (
																<ComingSoonTag variant={"PRIMARY"} />
															)}
														</ListItemButton>
													</ListItem>
												)
											)}
										</List>
									)}
								</div>
							)
						)}
						<div className={"hidden sm:inline-flex items-center gap-x-2"}>
							<Button
								variant={"contained"}
								color={"secondary"}
								endIcon={<FontAwesomeIcon icon={faArrowTrendUp} size={"xs"} />}
								sx={{ textTransform: "none", borderRadius: 4 }}
								href={"https://app.coinspaze.com"}
							>
								Login/Signup
							</Button>
							{/*<a*/}
							{/*	className="select-none"*/}
							{/*	href="https://play.google.com/store/apps/details?id=com.cryptospaze&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"*/}
							{/*>*/}
							{/*	<img*/}
							{/*		className={"w-36"}*/}
							{/*		alt="Get it on Google Play"*/}
							{/*		src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"*/}
							{/*	/>*/}
							{/*</a>*/}
							{/*<a*/}
							{/*	className="select-none"*/}
							{/*	href="https://apps.apple.com/us/app/coinspaze/id1627839517"*/}
							{/*>*/}
							{/*	<img*/}
							{/*		className={"w-[7.5rem]"}*/}
							{/*		alt="Get it on App Store"*/}
							{/*		src={AppStore}*/}
							{/*	/>*/}
							{/*</a>*/}
						</div>
						<IconButton
							className={"block text-white md:hidden"}
							onClick={(): void => setDrawerVisible(!drawerVisible)}
						>
							<FontAwesomeIcon icon={faBars} />
						</IconButton>
					</div>
				</div>
			</nav>
			<SwipeableDrawer
				onOpen={(): void => setDrawerVisible(true)}
				anchor={"bottom"}
				open={drawerVisible}
				onClose={(): void => setDrawerVisible(false)}
			>
				<Box
					onKeyDown={(): void => {
						setDrawerVisible(!drawerVisible);
					}}
					sx={{ width: "100%" }}
					className={"bg-primary text-white"}
				>
					{navigationData.map(
						({ id, title, tag, subList, href, disabled, icon }) => (
							<React.Fragment key={id}>
								<ListItemButton
									classes={{ root: "py-2 border-b border-highlight" }}
									onClick={(): void => {
										!subList && setDrawerVisible(false);
										!subList && href && navigate(href);
										subList &&
											setOpenSection({
												...openSection,
												[id]: !openSection[id] ?? true,
											});
									}}
									disabled={disabled}
								>
									{icon && (
										<ListItemIcon classes={{ root: "min-w-fit pr-5" }}>
											{icon}
										</ListItemIcon>
									)}
									<ListItemText
										primary={
											<span className={"flex flex-row gap-3"}>
												{title}
												{tag === TAG_TYPE.COMING_SOON && (
													<ComingSoonTag variant={"SECONDARY"} />
												)}
											</span>
										}
									/>
									{subList &&
										(openSection[id] ?? false ? (
											<FontAwesomeIcon icon={faAngleUp} />
										) : (
											<FontAwesomeIcon icon={faAngleDown} />
										))}
								</ListItemButton>
								{subList && (
									<Collapse
										in={openSection[id] ?? false}
										unmountOnExit={true}
										classes={{ wrapperInner: "border-b border-highlight" }}
									>
										<List className={"overflow-hidden p-0"}>
											{subList.map(
												({ id, title, tag, href, disabled, icon }) => (
													<ListItem
														key={id}
														className={"ml-10"}
														classes={{ root: "p-0" }}
													>
														<ListItemButton
															disabled={disabled}
															onClick={(): void => {
																setDrawerVisible(false);
																href && navigate(href);
															}}
														>
															{icon && (
																<ListItemIcon
																	classes={{ root: "min-w-fit pr-5" }}
																>
																	{icon}
																</ListItemIcon>
															)}

															<ListItemText
																primary={
																	<span className={"flex flex-row gap-3"}>
																		{title}
																		{tag === TAG_TYPE.COMING_SOON && (
																			<ComingSoonTag variant={"SECONDARY"} />
																		)}
																	</span>
																}
															/>
														</ListItemButton>
													</ListItem>
												)
											)}
										</List>
									</Collapse>
								)}
							</React.Fragment>
						)
					)}
				</Box>
			</SwipeableDrawer>
		</>
	);
};
