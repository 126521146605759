import React, { FC, ReactNode, useState } from "react";
import {
	Box,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
} from "@mui/material";
import { useTitle } from "../hooks/useTitle";

export const TABLE_CONTAINER_CSS =
	"bg-white rounded-xl shadow-xl container mx-auto max-w-5xl";
export const TABLE_CSS = "border-0 rounded-lg bg-primary";
export const TABLE_HEAD_CSS =
	"uppercase bg-transparent rounded-t-lg border-b-2 border-highlight";
export const TABLE_BODY_CSS =
	"border-2 rounded-lg border-t-0 bg-white text-color-text border-transparent";
export const TABLE_CELL_CSS =
	"border-0 tracking-wide leading-loose first:uppercase px-2 py-4 md:px-8 text-sm md:text-base";
export const TABLE_ROW_CSS =
	"border-b-2 last:border-b-0 last:rounded-lg border-highlight divide-x-2 divide-highlight grid grid-cols-2";

const pricingForBusinessesData: PriceDataType[] = [
	{
		fund: "Trading",
		fundType: "Buy/sell crypto",
		price: "1.5%",
		description: "Per Transaction",
	},
	{
		fund: "ACH",
		fundType: "In/Out",
		price: "$1.00",
		description: "Per Transaction",
	},
	{
		fund: "ACH",
		fundType: "Chargebacks",
		price: "$5.00",
		description: "Per Non sufficient funds",
		extra: "*$150 Fraud",
	},
	{
		fund: "Wires",
		fundType: "Domestic In/Out",
		price: "$15.00",
		description: "Per Transaction",
	},
	{
		fund: "Wires",
		fundType: "International In/Out",
		price: "$35.00",
		description: "Per Transaction",
	},
	{
		fund: "Wires",
		fundType: "Recalls",
		price: "$50.00",
		description: "Per Transaction",
	},
	{
		fund: "Checks",
		fundType: "Checks",
		price: "$15.00",
		description: "Per Transaction",
	},
	{
		fund: "Checks",
		fundType: "Outgoing",
		price: "$25.00",
		description: "Per Transaction",
	},
	{
		fund: "Digital Assets",
		fundType: "In/Out",
		price: "Network cost",
		description: "Per Transaction",
	},
	{
		fund: "Payment",
		fundType: "payment processing",
		price: "2%",
		description: "Per Transaction",
	},
	{
		fund: "Integration Fee",
		fundType: "payment processing and settlement",
		price: (
			<a
				href={"mailto:pricing@coinspaze.com"}
				className={"underline decoration-1 underline-offset-2"}
			>
				Contact Us
			</a>
		),
		description: "",
	},
];

const pricingData: PriceDataType[] = [
	{
		fund: "Trading",
		fundType: "Buy/sell crypto",
		price: "1.5%",
		description: "Per Transaction",
	},
	{
		fund: "ACH",
		fundType: "In/Out",
		price: "$1.00",
		description: "Per Transaction",
	},
	{
		fund: "ACH",
		fundType: "Chargebacks",
		price: "$5.00",
		description: "Per Non sufficient funds",
		extra: "*$150 Fraud",
	},
	{
		fund: "Wires",
		fundType: "Domestic In/Out",
		price: "$15.00",
		description: "Per Transaction",
	},
	{
		fund: "Wires",
		fundType: "International In/Out",
		price: "$35.00",
		description: "Per Transaction",
	},
	{
		fund: "Wires",
		fundType: "Recalls",
		price: "$50.00",
		description: "Per Transaction",
	},
	{
		fund: "Checks",
		fundType: "Checks",
		price: "$15.00",
		description: "Per Transaction",
	},
	{
		fund: "Checks",
		fundType: "Outgoing",
		price: "$25.00",
		description: "Per Transaction",
	},
	{
		fund: "Digital Assets",
		fundType: "In/Out",
		price: "Network cost",
		description: "Per Transaction",
	},
];

export const Pricing: FC = () => {
	useTitle("Pricing");
	const [currentTab, setCurrentTab] = useState("0");

	return (
		<>
			<div className={"bg-white py-24 text-color-text"}>
				<div
					className={
						"my-5 flex items-center justify-center pb-8 text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl"
					}
				>
					<span className={"text-color-header"}>Pricing</span>
					&nbsp;
				</div>
				<div className={"container mx-auto flex flex-col gap-3 px-2 pb-5"}>
					<Box className={"mb-5"}>
						<Tabs
							indicatorColor={"secondary"}
							textColor={"inherit"}
							aria-label="basic tabs example"
							classes={{
								flexContainer: "items-center justify-center gap-x-0.5",
								scroller: "border-0",
								root: "border-0 text-highlight",
								indicator: "bg-white text-secondary",
							}}
							onChange={(_, index): void => setCurrentTab(index)}
							value={currentTab}
						>
							<Tab
								value={"0"}
								className={"rounded-l-xl bg-secondary/60 px-8 text-highlight"}
								label="Individuals"
								classes={{
									selected: "bg-secondary/80",
								}}
							/>
							<Tab
								label="Businesses"
								classes={{
									selected: "bg-secondary/80",
								}}
								value={"1"}
								className={"rounded-r-xl bg-secondary/60 px-8 text-color-text"}
							/>
						</Tabs>
					</Box>
					<TableContainer className={`${TABLE_CONTAINER_CSS}`}>
						<Table className={`${TABLE_CSS}`}>
							<TableHead
								className={`bg-highlight ${TABLE_HEAD_CSS} bg-highlight`}
							>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell
										className={`bg-highlight ${TABLE_CELL_CSS} text-white`}
										sx={{ textAlign: "center" }}
									>
										Funds Processing
									</TableCell>
									<TableCell
										className={`bg-highlight ${TABLE_CELL_CSS} text-white`}
										sx={{ textAlign: "center" }}
									>
										Price
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className={`${TABLE_BODY_CSS} bf-white`}>
								{(currentTab === "0"
									? pricingData
									: pricingForBusinessesData
								).map(({ fund, fundType, price, description, extra }) => (
									<TableRow
										key={fund + " " + fundType}
										className={`${TABLE_ROW_CSS} text-color-text`}
									>
										<TableCell
											className={`${TABLE_CELL_CSS} text-color-text`}
											sx={{ textAlign: "center", fontSize: "16px" }}
										>
											<span
												className={
													"flex h-full flex-col items-center justify-center"
												}
											>
												{fund}
												<span className={"text-sm text-secondary"}>
													{fundType}
												</span>
											</span>
										</TableCell>
										<TableCell
											className={`${TABLE_CELL_CSS} text-color-text`}
											sx={{ textAlign: "center", fontSize: "16px" }}
										>
											<span
												className={
													"flex h-full flex-col items-center justify-center"
												}
											>
												{price}
												{description && (
													<span className={"text-sm text-secondary"}>
														{description}
													</span>
												)}
												<span className={"text-xxs"}>{extra}</span>
											</span>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
		</>
	);
};

type PriceDataType = {
	fund: string;
	fundType: string;
	price: ReactNode;
	description: string;
	extra?: string;
};
