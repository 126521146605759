import React, { FC } from "react";
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
} from "@mui/material";
import Neeraj from "../assets/people/Neeraj.png";
import PawamKumar from "../assets/people/Pavan_Kumar.png";
import LauraBermudez from "../assets/people/Laura_Bermudez.png";
import { useTitle } from "../hooks/useTitle";

const members: MemberType[] = [
	{
		name: "Neeraj Jain",
		image: Neeraj,
		link: "https://www.linkedin.com/in/neeraj-jain-5293025/",
		title: "Founder & CEO",
		position: "3 Patents (2 in Fintech)",
		companies: ["Carta", "eBay", "Schwab", "IBM"],
	},
	// {
	// 	name: "Rohit Jain",
	// 	image: "",
	// 	link: "https://www.linkedin.com/in/itsrohit/",
	// 	title: "Tech Co-founder",
	// 	position: "Engineering Leader with Accenture",
	// 	companies: ["Google", "Cisco", "Disney"],
	// },
	{
		name: "Laura Bermudez",
		image: LauraBermudez,
		link: "https://www.linkedin.com/in/laura-bermudez/",
		title: "Payment Strategist, Investor",
		position: "Board Member, VP of Engineering",
		companies: ["Carta", "eBay", "Paypal", "First Data"],
	},
	{
		name: "Pavan Kumar",
		image: PawamKumar,
		link: "https://www.linkedin.com/in/pavantalla/",
		title: "BD & Partnerships, Investor",
		position: "Ex Founder, Investor & Advisor",
		companies: ["Equant", "Reliance", "Ooredoo"],
	},
	// {
	// 	name: "Ron Pragides",
	// 	image: "",
	// 	link: "",
	// 	title: "Advisor",
	// 	position: "VSP Engineering at Trustly",
	// 	companies: ["Carta", "BigCommerce", "Twitter", "Salesforce"],
	// },
	// {
	// 	name: "Japit Tulsi",
	// 	image: "",
	// 	link: "",
	// 	title: "Advisor",
	// 	position: "CTO at MatterPort",
	// 	companies: ["Carta", "eBay", "Google", "Microsoft"],
	// },
];

export const Team: FC = () => {
	useTitle("Team");

	return (
		<div className="bg-white">
			<div className={" pt-24 pb-16 bg-white text-gray-700"}>
				<div
					className={
						"my-5 flex items-center justify-center text-4xl font-semibold tracking-wide lg:text-5xl"
					}
				>
					<span className={"text-secondary"}>Our</span>&nbsp;Team
				</div>
			</div>
			<Box
				className={
					"container mx-auto bg-white select-none px-2 pb-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 place-items-start h-full "
				}
			>
				{members.map(({ link, image, title, name, companies }, index) => (
					<a
						key={index}
						className={"justify-self-center"}
						target={"_blank"}
						href={link}
						rel="noreferrer"
					>
						<Card
							key={index}
							elevation={0}
							classes={{
								root: "mx-auto bg-transparent items-center flex flex-col",
							}}
						>
							<CardMedia
								sx={{ width: "auto" }}
								classes={{
									root: "z-40 bg-center aspect-square h-36 md:h-72 rounded-full",
								}}
								component="img"
								image={image}
							/>
							<CardActionArea>
								<CardContent classes={{ root: "h-full" }}>
									<Typography
										align={"center"}
										variant="h5"
										color="black"
										className={"capitalize"}
									>
										{name}
									</Typography>
									<Typography
										gutterBottom={true}
										align={"center"}
										variant="body2"
										fontWeight={"bold"}
										classes={{ root: "text-secondary" }}
									>
										{title}
									</Typography>
									<Typography
										gutterBottom={true}
										align={"center"}
										variant="subtitle2"
										color={"gray"}
									>
										Ex&nbsp;
										{companies.join(", ")}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</a>
				))}
			</Box>
		</div>
	);
};

type MemberType = {
	name: string;
	title: string;
	link: string;
	image: string;
	companies: string[];
	position: string;
};
