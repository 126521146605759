import React, { FC } from "react";
import { ReactComponent as Auth0 } from "../assets/logos/SVGs/auth0-ar21.svg";
import { ModalComponent } from "../components/ModalComponent";
import { useTitle } from "../hooks/useTitle";

export const Partners: FC = () => {
	useTitle("Partners");

	return (
		<div
			className={
				"flex scroll-mt-12 flex-col gap-14 bg-white py-4 text-gray-700 md:px-20"
			}
		>
			<div />
			<div />
			<div className="text-color-text  px-8 md:px-0">
				We at CoinSpaze work with numerous technology & business partners to
				deliver innovation and enhance customer experience in the crypto payment
				space. If you are an online B2B or B2C platform offering products or
				services and would like to integrate crypto payment solutions, reach out
				to us. We offer attractive commissions and plug & play technology
				solutions to enable crypto for your business associates and customers.
				Fill out the form below and our sales team will get in touch with you
				soon.
			</div>
			<div>
				<h1 className="text-center text-primary-header text-color-header mt-16 mb-8	underline-offset-1">
					Business Partners
				</h1>
				<div className="flex flex-col items-center md:flex-row md:justify-center md:gap-20">
					<div className="flex-col items-center">
						<img
							className="w-64 h-28 mb-4 self-start ml-[-1rem]"
							src="https://flutin.com/blog/wp-content/uploads/2020/02/Logo_Pic.png"
							alt="Flutin"
						/>
						<div className="text-center text-color-header">Flutin</div>
					</div>
					<div className="flex-col items-center">
						<img
							className="w-64 h-28 mb-4"
							src="https://media-exp1.licdn.com/dms/image/C560BAQH_wK-VDeQNBg/company-logo_200_200/0/1646436986963?e=2147483647&v=beta&t=1w8jp0_8zoK77X7pcDXbntjc7wFswjUT2HAbgReeyB8"
							alt=""
						/>
						<div className="text-center text-color-header">The NFT Brewery</div>
					</div>
				</div>

				<h1 className="text-center text-primary-header text-color-header mt-16">
					Technology Partners
				</h1>
				<div className="flex flex-col items-center md:items-stretch md:flex-row justify-center gap md:gap-20 px-8 md:px-0">
					<div className="flex-col items-center">
						<img
							className="w-48 h-32"
							src="https://findvectorlogo.com/wp-content/uploads/2022/03/prime-trust-llc-vector-logo-2022.png"
							alt="auth0-logo"
						/>
					</div>
					<div className="flex-col items-center mt-10">
						{/*<img className="w-48 h-32" src="Auth0" alt="auth0-logo" />*/}
						<Auth0 />
						{/* <div className="text-center">Auth0</div> */}
					</div>
				</div>
			</div>
			<div className="text-center mt-16">
				<ModalComponent prop={""} details={""} />
			</div>
		</div>
	);
};
