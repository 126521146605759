// Author: Akshant Jain (akshant.jain2@gmail.com)
// LICENSE: MIT
// Copyright © Akshant Jain 2021

export const animate: animationType = function (
	className = "parent",
	childClassName = "child",
	target = document
) {
	const animateChild = function (entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				setTimeout(() => {
					switch ((entry.target as HTMLElement).dataset.show ?? "") {
						case "topLeft":
							entry.target.classList.add("animate-fadeSlideInTopLeft");
							break;
						case "topRight":
							entry.target.classList.add("animate-fadeSlideInTopRight");
							break;
						case "bottomLeft":
							entry.target.classList.add("animate-fadeSlideInBottomLeft");
							break;
						case "bottomRight":
							entry.target.classList.add("animate-fadeSlideInBottomRight");
							break;
						case "right":
							entry.target.classList.add("animate-fadeSlideInRight");
							break;
						case "left":
							entry.target.classList.add("animate-fadeSlideInLeft");
							break;
						case "up":
							entry.target.classList.add("animate-fadeSlideInUp");
							break;
						case "down":
							entry.target.classList.add("animate-fadeSlideInDown");
							break;
						case "forward":
							entry.target.classList.add("animate-fadeSlideInForward");
							break;
						case "backward":
							entry.target.classList.add("animate-fadeSlideInBackward");
							break;
						default:
							break;
					}
					animate(childClassName, childClassName, entry.target as HTMLElement);
				}, Number((entry.target as HTMLElement).dataset.delay) ?? 0);
			}
		});
	};

	const observer = new IntersectionObserver(animateChild);
	const targets = target?.querySelectorAll(className);
	targets?.forEach(function (target) {
		observer.observe(target);
	});
};

export const handleScroll: handleScrollType = (elementId, tokens) => {
	if (window.scrollY > window.innerHeight - 50) {
		document.getElementById(elementId)?.classList.add(...tokens);
		return (
			((window.scrollY - window.innerHeight) /
				(document.body.scrollHeight - 2 * window.innerHeight)) *
			360
		);
	} else {
		document.getElementById(elementId)?.classList.remove(...tokens);
		return 0;
	}
};

type animationType = (
	className?: string,
	childClassName?: string,
	target?: Document | HTMLElement
) => void;

type handleScrollType = (elementId: string, tokens: string[]) => number;
