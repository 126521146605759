import React, { FC, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { Home } from "./pages/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import { Terms } from "./components/Terms";
import { Landing } from "./components/landing/Landing";
import { Contact } from "./components/Contact";
import { Developer } from "./components/Developer";
import { animate } from "./utils/animation";
import { Privacy } from "./components/Privacy";
import { NotFound } from "./pages/NotFound";
import { Pricing } from "./components/Pricing";
import { Team } from "./pages/Team";
import { Shoppers } from "./pages/Shoppers";
import { Merchants } from "./pages/Merchants";
import { Creators } from "./pages/Creators";
import { Partners } from "./pages/Partners";
import { About } from "./components/landing/About";
import { Widget } from "./components/Widget";
import { PopUpComponent } from "./components/PopUpComponent";
import { CookieConsentBanner } from "./components/CookieConsentBanner";

const firebaseConfig = {
	apiKey: "AIzaSyCKlM6ISNlf7wm2RXZwGj9PubjwWOnZukg",
	authDomain: "aravaliinc.firebaseapp.com",
	projectId: "aravaliinc",
	storageBucket: "aravaliinc.appspot.com",
	messagingSenderId: "593377590063",
	appId: "1:593377590063:web:8f4bcdefb380aa1284ffda",
	measurementId: "G-398KCL32MZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

export const App: FC = () => {
	const location = useLocation();
	useEffect(() => {
		if (location.hash) {
			const elementOffsetTop = document.getElementById(
				location.hash.replace("#", "")
			)?.offsetTop;
			console.log(location.hash, elementOffsetTop);
			window.scrollTo(0, elementOffsetTop ?? 0);
		} else {
			window.scrollTo(0, 0);
		}
		animate(".parent", ".child", document);
	}, [location]);

	return (
		<Home>
			<Widget />
			<CookieConsentBanner />
			<Routes>
				<Route path={"/"} element={<Landing />} />
				<Route path={"/pricing"} element={<Pricing />} />
				<Route path={"/contact"} element={<Contact />} />
				<Route path={"/team"} element={<Team />} />
				<Route path={"/developer"} element={<Developer />} />
				<Route path={"/terms-and-conditions"} element={<Terms />} />
				<Route path={"/privacy-policy"} element={<Privacy />} />
				<Route path={"/shoppers"} element={<Shoppers />} />
				<Route path={"/merchants"} element={<Merchants />} />
				<Route path={"/creators"} element={<Creators />} />
				<Route path={"/partners"} element={<Partners />} />
				<Route path={"/about"} element={<About />} />
				<Route path={"*"} element={<NotFound />} />
				<Route path={"/popup"} element={<PopUpComponent />} />
			</Routes>
		</Home>
	);
};
