import React, { FC, ReactNode } from "react";
import { ReactComponent as Money } from "../assets/TestSVG/money-svgrepo-com.svg";
import { ReactComponent as Usability } from "../assets/logos/SVGs/logo/usability.svg";
import { ReactComponent as Crypto } from "../assets/TestSVG/btc-svgrepo-com.svg";
import { ReactComponent as Instant } from "../assets/TestSVG/flash-svgrepo-com.svg";
import { ReactComponent as Dashboard } from "../assets/TestSVG/dashboard-svgrepo-com.svg";
import { ReactComponent as Web } from "../assets/TestSVG/world-wide-web-svgrepo-com.svg";
import Img from "../assets/Images/CreatorIMG.png";
import { ModalComponent } from "../components/ModalComponent";
import { useTitle } from "../hooks/useTitle";

const CreatorElement: FC<CreatorElementType> = ({
	title,
	icon,
	description = "We believe in crypto's future, we believe that in ten years it will",
	direction,
}: CreatorElementType) => {
	return (
		<div className={"m-5 flex flex-col gap-3 text-center"}>
			<div className={"flex h-20 items-center justify-center"}>{icon}</div>
			<p
				className={
					"select-none whitespace-nowrap font-semibold uppercase tracking-widest text-secondary"
				}
			>
				{title}
			</p>
			{description}
		</div>
	);
};

const Creator: CreatorElementType[] = [
	{
		title: "Simple Setup",
		icon: <Usability />,
		direction: "up",
		description:
			"Setup in few easy steps; get your unique QR Code for easy check-out by Customers.",
	},
	{
		title: "Low Fee",
		icon: <Money />,
		direction: "down",
		description:
			"We offer the most attractive transaction and crypto settlement fee!",
	},
	{
		title: "Instant Credit",
		icon: <Instant />,
		direction: "bottomLeft",
		description:
			"Get instant credit of either crypto or USD in your account. Bank transfer through ACH or wire transfer is available 24 x 7",
	},
	{
		title: "Creator Dashboard",
		icon: <Dashboard />,
		direction: "bottomRight",
		description:
			"Health of your business at a glance, view or generate reports with ease.",
	},
	{
		title: "Accept Crypto of Choice or USD",
		icon: <Crypto />,
		direction: "topRight",
		description:
			"For your product/service sale, accept crypto of choice or USD.",
	},
	{
		title: "Supports Web & Apps",
		icon: <Web />,
		direction: "topLeft",
		description: "Easy integration, be it your web portal or mobile app.",
	},
];

export const Creators: FC = () => {
	useTitle("Creators");

	const text = "Sign Up Now";
	const heading = "Creator Details";
	return (
		<div
			id={"advantage"}
			className={
				"flex scroll-mt-12 flex-col gap-14 lg:px-20 bg-white py-4 text-gray-700"
			}
		>
			<div />
			<div className="md:flex mx-12 md:mx-20 gap-10">
				<div className="basis-2/3">
					<div className="text-5xl md:pt-20 lg:pt-20 mb-16 mx-auto font-[600] text-center w-3/5">
						Increase your Income with crypto payments
					</div>
					<div className="text-center mx-auto pb-4 md:w-2/3 lg:w-1/2">
						Sell tickets, receive tips or accept donations with crypto. Let your
						fans and followers to pay with their wallets while you receive
						crypto of your choice or USD in your wallet instantly.
					</div>
				</div>
				<div className="basis-1/3  ">
					<img
						src={Img}
						alt=""
						className="h-[500px] sm:mt-8 lg:mt-auto mx-auto lg:ml-16"
					/>
				</div>
			</div>
			<div className="mx-8 md:mx-20 lg:mx-20">
				<div className="text-justify py-4">
					This area is for individual Creators(Entrepreneurs) offering personal
					services like online gaming, music, events, speaking, motivation,
					stock analysis, blogging, advisory, influencing, content creation
					etc., for a fee or subscription. Signup with us does not replace your
					existing Stripe, Square, Paypal or any other credit card, debit card
					and bank payment service. CoinSpaze is an additional service to offer
					your customers the option of crypto payments.
				</div>
				<div className="text-justify py-4">
					11.5 million Americans are in the creator economy and that 61%, or 7.1
					million people, earned money last year by doing so. This sector is
					poised for explosive growth, with another 3.2 million planning to
					become content creators over the next two years. (MBO Partners,
					Nov.2021). Web 3.0 is expected to bring a remarkable shift in the
					Creator economy due to decentralization and emergence of new features
					like NFTs that are mostly traded using crypto.
				</div>
				<div className="text-justify py-4">
					Get your offerings or services future ready! Accept crypto payments,
					either as a crypto of choice or USD. Creators familiar with crypto can
					choose the coin(or coins) of choice or simply accept USD to avoid the
					risk of crypto volatility. Our Creator account by default has both
					crypto and a zero balance USD account. You can trade in crypto, make
					ACH or wire transfers through the secure web or native app interface.
				</div>
				<div className="text-justify ">
					Finally, we value the privacy of yours and your Customer’s data and it
					is stored in a highly secure environment. Our user authentication is
					supported by Auth0, a global leader in credential management. We have
					partnered with Prime Trust, a licensed financial institution, for FIAT
					and crypto.
				</div>
			</div>
			<div className="md:mx-20 lg:mx-20">
				<div
					className={
						"md:grid-rows-auto container mx-auto grid max-w-10xl justify-items-center p-0 md:grid-cols-2 md:gap-y-1 md:gap-x-16 lg:grid-cols-3 lg:grid-rows-2 lg:gap-y-1 lg:gap-x-32"
					}
				>
					{Creator.map(({ title, icon, description, direction }, index) => (
						<CreatorElement
							key={index}
							title={title}
							icon={icon}
							description={description}
							direction={direction}
						/>
					))}
				</div>
			</div>
			<div className="text-center mt-16">
				<ModalComponent prop={text} details={heading} />
			</div>
		</div>
	);
};

type CreatorElementType = {
	title: string;
	description?: string;
	icon: ReactNode;
	direction:
		| "topLeft"
		| "topRight"
		| "bottomLeft"
		| "bottomRight"
		| "right"
		| "left"
		| "up"
		| "down";
};
