import React, { FC, ReactNode } from "react";
import { ReactComponent as Security } from "../assets/logos/SVGs/logo/security.svg";
import { ReactComponent as Usability } from "../assets/logos/SVGs/logo/usability.svg";
import { ReactComponent as Crypto } from "../assets/TestSVG/btc-svgrepo-com.svg";
import { ReactComponent as Instant } from "../assets/TestSVG/flash-svgrepo-com.svg";
import { ReactComponent as Dashboard } from "../assets/TestSVG/dashboard-svgrepo-com.svg";
import { ReactComponent as Web } from "../assets/TestSVG/world-wide-web-svgrepo-com.svg";
import { ReactComponent as Invoice } from "../assets/TestSVG/invoice-svgrepo-com.svg";
import Img from "../assets/Images/MerchantIMG.png";
import { ModalComponent } from "../components/ModalComponent";
import { ReactComponent as Money } from "../assets/TestSVG/money-svgrepo-com.svg";
import { useTitle } from "../hooks/useTitle";

const MerchantElement: FC<MerchantElementType> = ({
	title,
	icon,
	description = "We believe in crypto's future, we believe that in ten years it will",
	direction,
}: MerchantElementType) => {
	return (
		<div className={"m-5 flex flex-col gap-3 text-center"}>
			<div className={"flex h-20 items-center justify-center"}>{icon}</div>
			<p
				className={
					"select-none whitespace-nowrap font-semibold uppercase tracking-widest text-secondary"
				}
			>
				{title}
			</p>
			{description}
		</div>
	);
};

const Merchant: MerchantElementType[] = [
	{
		title: "Quick Setup",
		icon: <Usability />,
		direction: "up",
		description:
			"Simple two lines of code to integrate. Get your unique QR Code for easy check-out by customers.",
	},
	{
		title: "Low Fee",
		icon: <Money />,
		direction: "down",
		description:
			"We offer the most attractive transaction and crypto settlement fee!",
	},
	{
		title: "Instant Credit",
		icon: <Instant />,
		direction: "topRight",
		description:
			"Get instant credit of either crypto or USD in your account. Bank transfer through ACH or wire transfer is available 24 x 7.",
	},
	{
		title: "Merchant Dashboard",
		icon: <Dashboard />,
		direction: "bottomLeft",
		description:
			"Health of your business at a glance, view or generate reports with ease.",
	},
	{
		title: "Invoice (Coming Soon)",
		icon: <Invoice />,
		direction: "up",
		description:
			"Generate customer(shopper) invoices on the fly, with a QR code for easy payment. Get notified when the payment hits your account.",
	},
	{
		title: "Accept Crypto of Choice or USD",
		icon: <Crypto />,
		direction: "bottomRight",
		description:
			"For your product/service sale, accept crypto of choice or USD",
	},
	{
		title: "Supports Web & Apps",
		icon: <Web />,
		direction: "down",
		description: "Easy integration, be it your web portal or mobile app.",
	},
	{
		title: "Secure Shopping",
		icon: <Security />,
		direction: "topLeft",
		description:
			"We use industry grade security to ensure safe, reliable transactions",
	},
];

export const Merchants: FC = () => {
	useTitle("Merchants");

	const text = "Sign Up Now";
	const heading = "Merchant Details";

	return (
		<div
			id={"advantage"}
			className={
				"flex scroll-mt-12 flex-col gap-14 lg:px-20 bg-white py-4 text-gray-700"
			}
		>
			<div />
			<div className="md:flex mx-12  gap-10">
				<div className="basis-2/3">
					<div className="text-5xl  md:pt-20 lg:pt-20 mb-16 mx-auto font-[600] text-center w-3/5">
						Accept Crypto. <br /> Get paid in crypto or USD.
					</div>
					<div className="text-center mx-auto pb-4 md:w-2/3 lg:w-1/2">
						Increase your sales by accepting crypto payments from your customers
						on the fly. Choose to receive in crypto of choice or USD.
					</div>
				</div>
				<div className="basis-1/3 ">
					<img
						src={Img}
						alt=""
						className="h-[500px] sm:mt-8 lg:mt-auto mx-auto lg:ml-16"
					/>
				</div>
			</div>
			<div className="mx-8 md:mx-20 lg:mx-20">
				<div className="text-justify py-4 ">
					This area is for Businesses(Merchants) with online shopping/services,
					ecommerce, retail outlets, professional services, restaurants & bars,
					gaming portals etc.. Signup with us does not replace your existing
					Stripe, Square, Paypal or any other credit card, debit card and bank
					payment service. CoinSpaze is an additional service to offer customers
					the option of crypto payments.
				</div>
				<div className="text-justify py-4">
					Globally over 300 million customers use cryptocurrencies valued over
					$1.25 trillion. The crypto users in the United States are expected to
					reach 33.7 million, 12.8% of population, by the end of 2022, mostly in
					the age group of 25 to 44 years. In 2022, 3.6 million adults in the
					United States are expected to use crypto to make purchases, an
					increase of 68.6% over 2021. (Source: InsiderIntelligence, April,
					2022)
				</div>
				<div className="text-justify py-4">
					In United States, millennials and Gen-Z constitute 42.42% of the
					population, spend over $165 billion annually and are the majority
					holders of the crypto currency. Get your business future ready! Accept
					crypto payments, either as crypto or USD. Merchants familiar with
					crypto can choose the coin(or coins) of choice or simply accept USD to
					avoid the risk of crypto volatility. Our Merchant account by default
					has both crypto and USD wallet. You can easily trade in crypto, make
					ACH or wire transfers through the secure login page.
				</div>
				<div className="text-justify ">
					Finally, we value the privacy of yours and your Customer’s data and it
					is stored in a highly secure environment. If you chose to have it in
					your environment, our friendly API can be integrated to minimize the
					data with us. Our user authentication is supported by Auth0, a global
					leader in credential management. We have partnered with Prime Trust, a
					licensed financial institution, for FIAT and crypto.
				</div>
			</div>

			<div className="md:mx-20 lg:mx-20">
				<div
					className={
						"md:grid-rows-auto container mx-auto grid max-w-10xl justify-items-center p-0 md:grid-cols-2 md:gap-y-1 md:gap-x-16 lg:grid-cols-3 lg:grid-rows-2 lg:gap-y-1 lg:gap-x-32"
					}
				>
					{Merchant.map(({ title, icon, description, direction }, index) => (
						<MerchantElement
							key={index}
							title={title}
							icon={icon}
							description={description}
							direction={direction}
						/>
					))}
				</div>
			</div>
			<div className="text-center mt-16">
				<ModalComponent prop={text} details={heading} />
			</div>
		</div>
	);
};

type MerchantElementType = {
	title: string;
	description?: string;
	icon: ReactNode;
	direction:
		| "topLeft"
		| "topRight"
		| "bottomLeft"
		| "bottomRight"
		| "right"
		| "left"
		| "up"
		| "down";
};
