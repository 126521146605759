import React, { FC, useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngular,
	faHtml5,
	faReact,
	faVuejs,
} from "@fortawesome/free-brands-svg-icons";
import {
	ClientIdInfo,
	CoinSpazeBuyerCode,
	CoinSpazeSellerCode,
	NpmInstallAravali,
} from "../assets/developerCode/CoinSpazeReactCode";
import { useTitle } from "../hooks/useTitle";

export const Developer: FC = () => {
	useTitle("Developer");

	const [value, setValue] = useState("0");
	return (
		<div className={"bg-white py-24 text-gray-700"}>
			<div
				className={
					"my-5 flex items-center justify-center pb-8 text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl"
				}
			>
				<span className={"text-color-header"}>Developer Docs</span>
				&nbsp;
			</div>
			<div className={"container mx-auto  py-2"}>
				<TabContext value={value}>
					<Box>
						<Tabs
							value={value}
							variant="scrollable"
							scrollButtons="auto"
							onChange={(_, index): void => setValue(index)}
							aria-label="basic tabs example"
							indicatorColor="secondary"
							textColor={"secondary"}
							className={"overflow-y-scroll border-b border-highlight"}
							classes={{
								flexContainer: "gap-x-0.5",
							}}
						>
							<Tab
								icon={
									<FontAwesomeIcon
										className={"text-lg text-color-text"}
										icon={faReact}
									/>
								}
								iconPosition={"start"}
								className={"text-color-text"}
								label={
									<span>
										Market Places
										<br />
										<span className={"text-xs text-color-text"}>REACT</span>
									</span>
								}
								wrapped={true}
								value={"0"}
							/>
							<Tab
								icon={
									<FontAwesomeIcon
										className={"text-lg text-color-text"}
										icon={faReact}
									/>
								}
								iconPosition={"start"}
								className={"text-color-text"}
								label={
									<span>
										Merchants
										<br />
										<span className={"text-xs text-color-text"}>REACT</span>
									</span>
								}
								wrapped={true}
								value={"1"}
							/>
							<Tab
								icon={
									<FontAwesomeIcon
										className={"text-lg text-color-text"}
										icon={faAngular}
									/>
								}
								iconPosition={"start"}
								disabled={true}
								label={
									<span
										className={
											"flex flex-row items-center gap-x-1 text-gray-500"
										}
									>
										Angular
										<span
											className={
												"flex items-center rounded-full bg-highlight px-2 py-0.5 text-xs font-medium normal-case text-white"
											}
										>
											Coming Soon
										</span>
									</span>
								}
							/>
							<Tab
								icon={
									<FontAwesomeIcon
										className={"text-lg text-white"}
										icon={faVuejs}
									/>
								}
								iconPosition={"start"}
								disabled={true}
								label={
									<span
										className={
											"flex flex-row items-center gap-x-1 text-gray-500"
										}
									>
										Vue
										<span
											className={
												"flex items-center rounded-full bg-highlight px-2 py-0.5 text-xs font-medium normal-case text-white"
											}
										>
											Coming Soon
										</span>
									</span>
								}
							/>
							<Tab
								icon={
									<FontAwesomeIcon
										className={"text-lg text-white"}
										icon={faHtml5}
									/>
								}
								iconPosition={"start"}
								disabled={true}
								label={
									<span
										className={
											"flex flex-row items-center gap-x-1 text-gray-500"
										}
									>
										HTML
										<span
											className={
												"flex items-center rounded-full bg-highlight px-2 py-0.5 text-xs font-medium normal-case text-white"
											}
										>
											Coming Soon
										</span>
									</span>
								}
							/>
						</Tabs>
					</Box>

					<TabPanel value={"0"}>
						{ClientIdInfo}
						{NpmInstallAravali("1")}
						{CoinSpazeSellerCode("2")}
						{CoinSpazeBuyerCode("3")}
					</TabPanel>
					<TabPanel value={"1"}>
						{ClientIdInfo}
						{NpmInstallAravali("1")}
						{CoinSpazeSellerCode("2")}
					</TabPanel>
				</TabContext>
			</div>
		</div>
	);
};
