export enum SETTLEMENT_TIME {
	INSTANT = "INSTANT",
}
export enum SETTLEMENT_FEE {
	ZERO = "ZERO",
}

export enum PAYMENT_COMMISSION {
	ONE_PERCENT = "1%",
}

export enum SWAP_FEE {
	NONE = "N/A",
	POINT_FIVE = "0.5%",
	ONE_PERCENT = "1%",
}

export enum TAG_TYPE {
	COMING_SOON,
	BETA,
}
