import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import IMG1 from "../../assets/Images/CreatorIMG.png";
import IMG2 from "../../assets/Images/MerchantIMG.png";
import IMG3 from "../../assets/Images/ShopperIMG.png";
import { ReactComponent as Money } from "../../assets/TestSVG/money-svgrepo-com.svg";
import { ReactComponent as Safe } from "../../assets/TestSVG/btc-svgrepo-com.svg";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const FOOT_NOTE = (
	<div>
		Valid for new users who joined after July 1, 2022 and trade cryptocurrency on CoinSpaze. Limited while
		supplies last or CoinSpaze revokes this incentive at its sole discretion.
		CoinSpaze reserves the right to change the terms, eligibility criteria, and
		payouts for all incentives at any time, for any reason. Void where
		prohibited or if CoinSpaze determines that the customer is not eligible for
		the offer.
	</div>
);

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
	outline: "none",
};

const details = [
	{
		punchLine: "Powering commerce with Crypto",
		property: {
			first: {
				msg: "<2% Fee",
				icon: <Money />,
			},
			second: {
				msg: "Zero Risk",
				icon: <Safe />,
			},
			third: {
				msg: "Instant Settlement",
				icon: <Money />,
			},
		},
		reward: "Trade $10,000+ of crypto monthly and earn 50% commission back - Enjoy more profits with every trade.",
		img: IMG1,
	},
	{
		punchLine: "Increase income with Crypto payments",
		property: {
			first: {
				msg: "Low fee",
				icon: <Money />,
			},
			second: {
				msg: "Instant transfers from fans",
				icon: <Money />,
			},
			third: {
				msg: "Receive with QR Code",
				icon: <Money />,
			},
		},
		reward:
			"Refer and earn $5 when your referral buys or sells $500 of crypto in their CoinSpaze account",
		img: IMG2,
	},
	{
		punchLine: "Trade, Pay or Transfer crypto with ease",
		property: {
			first: {
				msg: "Fixed Fee",
				icon: <Money />,
			},
			second: {
				msg: "Crypto Payments",
				icon: <Money />,
			},
			third: {
				msg: "Designed for day trading",
				icon: <Money />,
			},
		},
		reward:
			"Get $5 when you buy/sell $1000 of Crypto in your CoinSpaze account",
		img: IMG3,
	},
];
export const HeaderCarousel: FC = () => {
	const [open, setOpen] = useState(false);

	const [openFootNote, setOpenFootNote] = useState(false);

	const handleClose = (): void => setOpenFootNote(false);
	const handleOpen = (): void => setOpenFootNote(true);

	return (
		<>
			<Snackbar
				open={openFootNote}
				autoHideDuration={10000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity="info" sx={{ width: "90%" }}>
					{FOOT_NOTE}
				</Alert>
			</Snackbar>
			<Swiper
				navigation={{
					enabled: true,
					hideOnClick: false,
				}}
				pagination={{
					clickable: true,
					type: "bullets",
					bulletClass: "inline-block rounded-full h-2 w-2 bg-gold/30 mx-1",
					progressbarFillClass:
						"!bg-gold absolute origin-top-left w-screen h-1 top-0 left-0",
					bulletActiveClass: "bg-gold !opacity-100",
					enabled: true,
				}}
				modules={[Autoplay, EffectFade, Navigation, Pagination]}
				effect={"fade"}
				slidesPerView={1}
				fadeEffect={{ crossFade: true }}
				autoplay={{ delay: 5000, stopOnLastSlide: false }}
			>
				{details.map((item, index) => (
					<SwiperSlide key={index}>
						<div
							className="md:flex md:items-center md:justify-center"
							key={index}
						>
							<div
								className={
									"container mx-auto flex h-full max-w-6xl justify-center flex-col gap-6 py-24 px-16"
								}
							>
								<div
									className={
										"text-center text-4xl capitalize font-[600] leading-normal md:text-3xl lg:text-4xl text-white"
									}
								>
									{item.punchLine}
								</div>
								<div className="text-left ml-16 text-white">
									<ul className="text-2xl list-disc">
										<li>{item.property.first.msg}</li>
										<li>{item.property.second.msg}</li>
										<li>{item.property.third.msg}</li>
									</ul>
								</div>
								<div className=" text-xl leading-normal font-[600] md:text-2xl lg:text-3xl text-gold text-center">
									{item.reward}
									<sup className={"cursor-pointer"} onClick={handleOpen}>
										*
									</sup>
									<div
										className="text-center text-gold text-xxs cursor-pointer hover:font-[800]"
										onClick={handleOpen}
									>
										{/* <span className="text-xs align-super">*</span>Terms Apply */}
									</div>
								</div>
								<span className="text-center">
									<Button
										variant={"contained"}
										color={"secondary"}
										endIcon={
											<FontAwesomeIcon icon={faArrowTrendUp} size={"xs"} />
										}
										sx={{
											textTransform: "none",
											px: 5,
											fontSize: 16,
											borderRadius: 4,
										}}
										href={"https://app.coinspaze.com"}
									>
										Sign Up
									</Button>
								</span>
							</div>
							<div className="select-none container hidden md:block mx-auto px-12 w-3/4 3xl:px-0 3xl:py-0 2xl:ml-[5%] 2xl:w-[85%] 3xl:ml-[40%] 3xl:w-1/2">
								<div className="rounded-md w-2/3 ml-28">
									<img src={item.img} alt="" className="object-fit" />
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<span className="absolute right-0 top-0">
						<IconButton onClick={handleClose}>
							<Close />
						</IconButton>
					</span>
					<Typography variant="h5" className="text-center">
						{" "}
						Sign Up Reward
					</Typography>
					<Typography id="modal-modal-description" sx={{ pt: 2 }}>
						Earn up to a total of $250 in referrals, $5 when your referral
						creates an account and get KYC approved with CoinSpaze. You must be
						approved by CoinSpaze to receive the reward.
					</Typography>
				</Box>
			</Modal>
		</>
	);
};
