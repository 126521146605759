import React, { FC, ReactNode } from "react";
import { ReactComponent as Security } from "../assets/logos/SVGs/logo/security.svg";
import { ReactComponent as Easy } from "../assets/TestSVG/cart-svgrepo-com.svg";
import { ReactComponent as Money } from "../assets/TestSVG/money-svgrepo-com.svg";
import Img from "../assets/Images/ShopperIMG.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import { useTitle } from "../hooks/useTitle";

const ShopperElement: FC<ShopperElementType> = ({
	title,
	icon,
	description = "We believe in crypto's future, we believe that in ten years it will",
	direction,
}: ShopperElementType) => {
	return (
		<div className={"m-5 flex flex-col gap-3 text-center"}>
			<div className={"flex h-20 items-center justify-center"}>{icon}</div>
			<p
				className={
					"select-none whitespace-nowrap font-semibold uppercase tracking-widest text-secondary"
				}
			>
				{title}
			</p>
			{description}
		</div>
	);
};

const Shopper: ShopperElementType[] = [
	{
		title: "Easy Checkout",
		icon: <Easy />,
		direction: "topLeft",
		description:
			"Single Click checkout and easy access to your crypto wallet. No signup required.",
	},
	{
		title: "Low Fee",
		icon: <Money />,
		direction: "down",
		description:
			"We offer free crypto transfers and an attractive trading fee.",
	},
	{
		title: "Security",
		icon: <Security />,
		direction: "bottomRight",
		description:
			"We use industry grade security to ensure safe, reliable transactions.",
	},
];
export const Shoppers: FC = () => {
	useTitle("Shoppers");

	return (
		<div
			id={"advantage"}
			className={
				"flex scroll-mt-12 flex-col gap-14 lg:px-20 bg-white py-4 text-gray-700"
			}
		>
			<div />
			<div className="md:flex mx-12 md:mx-20 gap-10">
				<div className="basis-2/3 ">
					<div className="text-5xl  md:pt-20 lg:pt-20 mb-16 mx-auto font-[600] text-center w-3/5">
						Shopping with your crypto wallet is lot easier and trading too!
					</div>
					<div className="text-center mx-auto pb-4 md:w-2/3 lg:w-1/2">
						Easy checkout to pay merchants or shop online with your existing
						crypto wallet. Highly secure and low transaction fees makes it
						convenient to trade crypto. Pay with your CoinSpaze wallet or other
						existing wallet(Coinbase etc).
					</div>
					<div className="text-center mt-16">
						<Button
							variant={"contained"}
							color={"secondary"}
							endIcon={<FontAwesomeIcon icon={faArrowTrendUp} size={"lg"} />}
							sx={{
								textTransform: "none",
								px: 5,
								fontSize: 16,
								borderRadius: 4,
							}}
							href={"https://app.coinspaze.com"}
						>
							Login/Signup
						</Button>
					</div>
				</div>
				<div className="basis-1/3  ">
					<img
						src={Img}
						alt=""
						className="h-[500px] sm:mt-8 lg:mt-auto mx-auto lg:ml-16"
					/>
				</div>
			</div>
			<div className="md:mx-20 lg:mx-20">
				<div
					className={
						"md:grid-rows-auto container mx-auto grid max-w-7xl justify-items-center p-0 md:grid-cols-2 md:gap-y-1 md:gap-x-16 lg:grid-cols-3 lg:grid-rows-1 lg:gap-y-1 lg:gap-x-32"
					}
				>
					{Shopper.map(({ title, icon, description, direction }, index) => (
						<ShopperElement
							key={index}
							title={title}
							icon={icon}
							description={description}
							direction={direction}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

type ShopperElementType = {
	title: string;
	description?: string;
	icon: ReactNode;
	direction:
		| "topLeft"
		| "topRight"
		| "bottomLeft"
		| "bottomRight"
		| "right"
		| "left"
		| "up"
		| "down";
};
