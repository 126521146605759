import React, { FC, Fragment, ReactNode } from "react";
import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";
import { Banner } from "../components/landing/Banner";
import { Extras } from "../components/Extras";

export const Home: FC<HomeComponent> = ({ children }) => {
	return (
		<Fragment>
			<main
				className={
					"relative flex min-h-screen h-full flex-col scroll-smooth text-gray-600 w-screen"
				}
			>
				<NavBar />
				{children}
				<Banner />
				<Extras />
				<Footer />
			</main>
		</Fragment>
	);
};

type HomeComponent = {
	children: ReactNode | ReactNode[];
};
