import React, { FC } from "react";
import AppStore from "../../assets/badges/AppStore.svg";

export const Banner: FC = () => {
	return (
		<section className={"scroll-mt-12 bg-white  pt-8"} id={"app-download"}>
			<div className={"bg-white p-8 py-8 text-gray-700"}>
				<div
					className={
						"container mx-auto flex max-w-4xl flex-col justify-center gap-4 text-center md:flex-row md:text-left"
					}
				>
					<p
						className={
							"text-center text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl text-wrap"
						}
					>
						<span className={"text-gold"}>Download</span> CoinSpaze
						<br />
						<span
							className={
								"mt-10 flex-col md:flex-row flex items-center justify-center gap-5"
							}
						>
							<a
								className="select-none"
								href="https://play.google.com/store/apps/details?id=com.cryptospaze&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
							>
								<img
									className={"w-44 md:w-48"}
									alt="Get it on Google Play"
									src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
								/>
							</a>
							<a
								className="select-none"
								href="https://apps.apple.com/us/app/coinspaze/id1627839517"
							>
								<img
									className={"w-40 md:w-[9.5rem]"}
									alt="Get it on App Store"
									src={AppStore}
								/>
							</a>
						</span>
					</p>
				</div>
			</div>
		</section>
	);
};
