import React, { FC } from "react";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import {
	TABLE_BODY_CSS,
	TABLE_CELL_CSS,
	TABLE_CONTAINER_CSS,
	TABLE_CSS,
	TABLE_HEAD_CSS,
	TABLE_ROW_CSS,
} from "../styles/muiStyles";
import { Link } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";

export const Terms: FC = () => {
	useTitle("Terms & Conditions");

	return (
		<div
			className={
				"flex flex-col gap-y-5  p-5 py-24 bg-white text-gray-700 lg:gap-y-10 lg:p-24"
			}
		>
			<div
				className={
					"my-5 flex items-center justify-center text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl"
				}
			>
				<span className={"text-secondary"}>Terms</span>&nbsp;&&nbsp;
				<span className={"text-secondary"}>Conditions</span>
			</div>
			<span
				className={
					"flex flex-row items-center justify-center text-lg font-bold uppercase capitalize text-secondary"
				}
			>
				Effective Date: March 31st, 2022
			</span>
			<div
				className={
					"container mx-auto flex max-w-5xl flex-row items-center justify-items-center gap-x-10 pb-8"
				}
			>
				<p
					className={
						"w-full text-justify text-sm leading-8 tracking-wide text-gray-700 md:text-base"
					}
				>
					These TERMS AND CONDITIONS (this &quot;Agreement&quot;) are agreed to
					between Aravali, Inc. a Delaware corporation (“Aravali”, “CoinSpaze”,
					“we” or “us”) and you (“You” or “Your”) and govern Your use of and
					access to the Aravali Platform. Aravali offers its cryptocurrency
					trading and payment processing platform comprised of mobile
					applications and websites provided by and on behalf of Aravali
					(collectively, the “Platform”). As used in this Agreement, the term
					“Platform” includes all such mobile applications and websites and all
					Services and Content (as defined below). The Platform allows merchants
					and their employees and contractors (each, a “User”) to process
					cryptocurrency transactions and verify customer’s identities, as
					described on the Platform (“Services”). Before becoming a User, and
					before accessing or using the Platform, You are required to agree to
					the terms of this Agreement. Unless You and Aravali have each signed a
					separate agreement regarding the Platform, this Agreement is the
					complete and exclusive agreement between You and Aravali regarding
					Your access to and use of the Platform and supersedes any oral or
					written proposal, unsigned agreements or other communication between
					You and Aravali regarding Your access to and use of the Platform.
					PLEASE CAREFULLY READ THIS AGREEMENT. BY ACCESSING OR USING THE
					PLATFORM, OR BY CLICKING A BOX THAT STATES THAT YOU ACCEPT OR AGREE TO
					THESE TERMS, YOU AGREE THAT YOU HAVE READ AND AGREE TO BE BOUND BY
					THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF
					ANOTHER ORGANIZATION OR ENTITY (“ENTITY”), YOU REPRESENT THAT YOU HAVE
					AUTHORITY TO BIND THAT ENTITY TO THIS AGREEMENT. IF YOU DO NOT HAVE
					SUCH AUTHORITY OR YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO
					ACCESS OR USE THE PLATFORM OR CLICK THAT YOU ACCEPT OR AGREE TO THESE
					TERMS. IF YOU DO NOT AGREE TO THIS AGREEMENT, OR DO NOT MEET THE
					QUALIFICATIONS INCLUDED IN THIS AGREEMENT, Aravali IS NOT WILLING TO
					PROVIDE YOU WITH ACCESS TO OR USE OF THE PLATFORM AND YOU MUST NOT
					ACCESS OR USE THE PLATFORM. THESE TERMS CONTAIN AN ARBITRATION
					PROVISION, WHICH LIMITS YOUR RIGHTS TO BRING AN ACTION IN COURT AND
					HAVE DISPUTES DECIDED BY A JUDGE OR JURY, AND PROVISIONS THAT LIMIT
					Aravali’S LIABILITY TO YOU. YOUR BREACH OF ANY PROVISION OF THIS
					AGREEMENT WILL AUTOMATICALLY, WITHOUT THE REQUIREMENT OF NOTICE OR
					OTHER ACTION, REVOKE AND TERMINATE YOUR RIGHT TO ACCESS THE PLATFORM.{" "}
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						1. Term
					</span>
					<br />
					This Agreement is entered into as of the earlier of the date You first
					access or use Platform (the “Effective Date”) and will continue until
					terminated as set forth herein. <br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						2. Modifications
					</span>
					<br />
					Aravali reserves the right, at any time, to modify the Platform, as
					well as the terms of this Agreement, whether by making those
					modifications available on the Platform or by providing notice to You
					as specified in this Agreement. Any modifications will be effective
					upon posting to the Platform or delivery of such other notice, unless
					otherwise required by law. You may cease using the Platform or
					terminate this Agreement at any time if you do not agree to any
					modification. However, You will be deemed to have agreed to any and
					all modifications through Your continued use of the Platform following
					such notice. <br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						3. Eligibility
					</span>
					<br />
					The Platform is intended for use by individuals 18 years of age and
					older. Additionally, to access and use the Platform you may need to
					fulfill certain other legal obligations or criteria required in your
					jurisdiction. Aravali makes no representations that the Platform is
					appropriate or available for use in all jurisdictions. Accessing any
					part of the Platform is prohibited from any jurisdictions where access
					to or use of the Platform is prohibited. <br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						4. Accounts and Conduct
					</span>{" "}
					<br />
					<span className={"mt-2 font-medium text-secondary"}>
						4.1 Account Creation and Responsibility
					</span>
					<br /> Before becoming a User, You must establish an account on the
					Platform (an “Account”). Approval of Your request to establish an
					Account will be at the sole discretion of Aravali. Each Account and
					the User identification and password for each Account (the “Account
					ID”) is for Your sole use and may only be used by You and Your
					authorized employees and contractors. You may not distribute or
					transfer Your Account or Account ID or provide a third-party with the
					right to access Your Account or Account ID. You are solely responsible
					for all use of the Platform through Your Account. All transactions
					completed through Your Account or under your Account ID will be deemed
					to have been lawfully completed by You. You will ensure the security
					and confidentiality of Your Account ID and will notify Aravali
					immediately, by email at{" "}
					<a
						className={
							"underline hover:text-secondary-hover visited:text-purple-600"
						}
						href={"mailto:support@aravaliinc.com"}
					>
						support@aravaliinc.com
					</a>
					, if any Account ID is lost, stolen or otherwise compromised. In
					connection with establishing an Account, You will be asked to submit
					certain information about Yourself (“Registration Information”). You
					agree that: (1) all Registration Information You provide will be true
					and complete; and (2) You will maintain and promptly update Your
					Registration Information to keep it accurate and current. You may not:
					(a) select or use an Account ID of another person with the intent to
					impersonate that person; and (b) use an Account ID that Aravali, in
					its sole discretion, deems offensive. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						4.2. Your Content
					</span>
					<br /> You are solely responsible for all Content you provide, upload,
					submit, or post to, or generate through access to or use of the
					Platform including by connecting or communicating with other Users
					(“Your Content”). Your responsibility for Your Content extends to
					resolving any disputes that may arise between you and any User or
					other entity because of Your Content. By providing, uploading,
					submitting, posting, or generating Your Content, you grant Aravali and
					its authorized representatives and contractors a perpetual and
					non-exclusive right and license to use, process, store, and transmit,
					and disclose Your Content (a) to provide the Services and fulfill
					other obligations described in this Agreement and (b) to further
					develop and provide services for Aravali customers. You understand
					that in certain contexts Your Content may be visible to, sent to, and
					viewed by other Users and you expressly waive any privacy rights you
					may otherwise have in Your Content in such contexts. You agree to
					allow us, if we elect in our sole discretion, to provide Your Content
					to other Users in connection with identity verification Services,
					described below. You represent, warrant, and covenant that Your
					Content: (a) does not violate this Agreement or any applicable laws;
					(b) is not libelous, defamatory, obscene, abusive, pornographic,
					threatening, or an invasion of privacy; (c) does not constitute an
					infringement or misappropriation of the IPR (as defined below) or
					other rights of any third-party; (d) is not an advertisement or
					solicitation of funds, goods, or services; (e) is not false,
					misleading, or inaccurate; or (f) could not be considered junk mail,
					spam, a part of a pyramid scheme, a disruptive commercial message or
					disruptive advertisement. Aravali is not responsible or liable for any
					deletion, correction, destruction, damage, loss or failure to store or
					back-up any of Your Content. You agree that You have all right, title,
					interest and consent in Your Content necessary to allow Aravali to use
					Your Content for the purposes for which You provide Your Content to
					Aravali. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						4.3. Your Conduct
					</span>
					<br /> You agree not to harass, advocate harassment, or to engage in
					any conduct that is abusive or harmful to any Entity. We reserve the
					right, but are not obligated, to investigate and/or prohibit any
					conduct, or remove or refuse to post any Content (including Your
					Content), that we deem in our sole discretion to be unlawful, harmful,
					in breach of this Agreement, or otherwise offensive to You, the
					Platform, Users, our customers, our rights, or any Entity. We assume
					no liability for any action or inaction with respect to Your conduct,
					communication, transactions, or Content. Additionally, we may disclose
					any Content or electronic communication of any kind: (i) to satisfy
					any law or request by a governmental authority; (ii) if such
					disclosure is necessary or appropriate to operate the Platform; (iii)
					to protect our rights or property, our Users and customers, You, or
					any other Entity; or (iv) if, in our sole discretion, such Content or
					electronic communication should be referred to law enforcement or
					other government authorities.
					<br />
					<span className={"mt-2 font-medium text-secondary"}>
						4.4. Retention
					</span>
					<br /> Your Content and Account information will be retained according
					to Aravali’s Privacy Policy located at&nbsp;
					<Link
						className={
							"underline hover:text-secondary-hover visited:text-purple-600"
						}
						to={"/privacy-policy"}
					>
						https://www.aravaliinc.com/privacy-policy
					</Link>
					&nbsp;(“Privacy Policy”).
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						5. Access
					</span>{" "}
					<br />
					<span className={"mt-2 font-medium text-secondary"}>
						5.1. To Aravali
					</span>
					<br /> Subject to Your compliance with this Agreement, Aravali will
					permit You to access and use the Platform solely for lawful
					non-consumer business purposes in accordance with the terms of this
					Agreement and any other agreement You agree to before being given
					access to any specific areas of the Platform. Aravali requires You to
					enable cryptocurrency acceptance through the platform, if You are
					interested in accepting debit cards and/or secure bank transfers as
					well. Any additional agreement is in addition to this Agreement and
					will govern Your use of the portions of the Platform to which the
					additional agreement applies in the event of a conflict between the
					terms of this Agreement and the additional agreement. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						5.2. To Content
					</span>
					<br />
					Unless otherwise noted on the Platform, other than Your Content, all
					Content available through the Platform (“Platform Content” or
					“Content”) is owned by Aravali, the Users providing that Content, or
					Aravali’s other Content providers. All Platform Content is for
					informational purposes only and You are solely responsible for
					verifying the accuracy, completeness, and applicability of all
					Platform Content and for Your use of any Platform Content. Subject to
					Your compliance with this Agreement, You may access the Platform
					Content solely for Your own internal business purposes in connection
					with Your own use of the Platform. You will not, and will not permit
					any third-party to: (a) alter, modify, reproduce, or create derivative
					works of any Platform Content; (b) distribute, sell, resell, lend,
					loan, lease, license, sublicense or transfer any Platform Content; or
					(c) alter, obscure or remove any copyright, trademark or any other
					notices that are provided on or in connection with any Platform
					Content. Aravali has not verified the accuracy of, and will not be
					responsible for any errors or omissions in, any Platform Content.
					Without limiting the foregoing, Aravali will not be held liable to You
					or any other third-party for any Platform Content (or any other
					Content, including Your Content) under any law, including the
					Communications Decency Act or CDA, 47 U.S.C. § 230. Except as set
					forth in this Agreement, You are granted no licenses or rights in or
					to any Platform Content, or any IPR (as defined below) therein or
					related thereto. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						5.3. To Third-Party Sites and Services
					</span>
					<br />
					The Platform may contain links to third-party sites that are not under
					the control of Aravali. Unless otherwise noted, any other site
					accessed from the Platform is independent from us, and we have no
					control over and are not responsible for its content. Links to
					third-party websites are provided for your convenience only and you
					access them solely at your own risk. You acknowledge and agree that
					Aravali shall not be liable or responsible, directly or indirectly,
					for any damage or loss caused or alleged to be caused by or related to
					the use of or reliance on any content, goods, or services available
					through any third-party website or resource. Your access and use of
					the third-party sites are governed by the terms of use and privacy
					policies of these third-party sites. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						5.4. To Third-Party Services
					</span>
					<br />
					The Platform may also provide You with the option, and use of certain
					Platform features may require you, to obtain access to services,
					content, functionality, software and other things developed, provided,
					or maintained by third party service providers (collectively, “Third
					Party Services”). All Third Party Services are provided by third
					parties and are not under the control of Aravali. You acknowledge and
					agree that Aravali shall not be liable or responsible, directly or
					indirectly, for Your access to or use of any Third Party Services,
					including any damages, losses, liabilities, failures, or problems
					caused by, related to, or arising from any Third Party Services. Your
					use of and access to any Third Party Services is solely between you
					and the third party provider of the Third Party Services. Your access
					to and use of any Third Party Services is subject to any additional
					terms, conditions, agreements, or privacy policies provided or entered
					into in connection with the Third Party Services (each, a “Third Party
					Agreement”). The terms of any Third Party Agreement (which may include
					payment of additional fees) will apply to the applicable Third Party
					Services provided under that Third Party Agreement but will not
					otherwise apply to Your access to or use of the Platform. Except as
					set forth in this Agreement, in the event of a conflict between the
					terms of this Agreement and a Third Party Agreement, the terms of the
					Third Party Agreement will control with respect to Your access to and
					use of any Third Party Services provided under that Third Party
					Agreement. This Agreement will continue to control in all other
					respects. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						5.4.1. To pay by debit, credit, wire or bank transfer
					</span>
					<br />
					The Platform may provide Payers access to a debit, credit, wire and
					bank transfer features enabled through Aravali’s 3rd party payment
					processing provider
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						6. Services
					</span>
					<br />{" "}
					<span className={"mt-2 font-medium text-secondary"}>
						6.1. Transactions
					</span>
					<br />
					Any transaction processed through the Platform will subject to the
					cryptocurrency exchange rate and other terms determined by Aravali in
					its sole discretion. By approving a transaction, in addition to the
					terms of this Agreement, You agree to be bound by the exchange rate
					and terms applicable to that transaction. The terms determined by the
					Aravali will apply in all cases except when they contradict or violate
					this Agreement, violate applicable law, or if both parties of the
					transaction consent to alter the terms and conditions of the
					transaction. You undertake all transactions through the Platform
					solely at Your own risk. You will only communicate regarding a
					contemplated transaction via the Platform’s chat functionality. For
					each transaction, You bear sole responsibility for confirming (a) Your
					customer’s identity and payment information, (b) Your receipt of
					payment in full, and (c) that payment was made according to the
					instructions given through Platform. You represent, warrant and
					covenant that You will not make, provide, receive or attempt to make,
					provide, or receive payments from or to any person or Entity that is
					(x) a third-party, other than Your customer or (y) currently subject
					to any U.S. sanctions administered by the Office of Foreign Assets
					Control of the U.S. Treasury Department. After You have approved a
					transaction, it is not possible to cancel, reverse, dispute or
					otherwise recover or return Your assets. Once a transaction has been
					initiated, Aravali has no control over the transaction and the
					transaction can only be accepted by confirmation via the underlying
					blockchain network. You assume sole responsibility for such
					confirmation and acknowledge that Aravali assumes no liability with
					respect thereto. You must notify Aravali of any payment transaction
					discrepancies within 180 days from the date the original transaction
					was initiated.
					<br />
					<span className={"mt-2 font-medium text-secondary"}>
						6.2. Identity Verification
					</span>
					<br /> Aravali may require that You verify Your identity before
					accessing or using certain Services. Promptly following Aravali’s
					request, You will provide Aravali any and all Content, including Your
					full name and any documents or photographs, necessary for Aravali to
					verify Your identity. You hereby represent, warrant and covenant that
					such Content is accurate, current and complete. Aravali reserves the
					right to freeze Your Account until Your identity has been verified
					pursuant to this Section and to terminate Your Account should you fail
					to comply with your obligations under this Section. You, and You (and,
					if acting in a representative capacity, individually and for the
					Entity you represent) authorize Aravali and its agents to make such
					investigative inquiries and request such third-party reports as it
					deems necessary to verify your identity and that of your principals
					and other agents. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						6.3. Blockchain Forks and New Cryptocurrencies
					</span>
					<br /> From time to time, new and alternative cryptocurrencies and/or
					forks of the digital assets available on the blockchain may be
					created. This may result in holders of private keys containing a
					digital assets balance receiving a corresponding amount of the new
					cryptocurrency on the newly created blockchain (“Air-Drop”). Aravali
					reserve the right, in its sole discretion, to determine whether the
					Platform will support any new cryptocurrencies and/or blockchain
					forks. If we decide to not support a new cryptocurrency or blockchain
					fork, we may, but are not obligated to, compensate Users who held
					digital assets in their Aravali account at the time of the Air-Drop in
					a manner we deem appropriate. We reserve the right to provide such
					compensation by converting the new cryptocurrency into digital assets
					and sharing such digital assets between Users who held a digital
					assets balance on their account at the time of the Air-Drop. We may
					charge You a processing fee that we deem reasonable (in our sole
					discretion) to process such a conversion. This Agreement, including
					any reference to digital assets, applies to any new cryptocurrency
					supported by the Platform. Under no circumstance will Aravali be
					liable to You for any damage, losses, expenses or harm resulting from
					Aravali’s exercise of rights under this Section.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						7. Fees
					</span>
					<br />
					You will pay Aravali all fees required to access and use of the
					Platform (“Fees”) as set forth in the following fee schedule:&nbsp;
					<Link
						className={
							"underline hover:text-secondary-hover visited:text-purple-600"
						}
						to={"/pricing"}
					>
						https://www.aravaliinc.com/pricing
					</Link>
					. The Fees are subject to change without notice, at any time and You
					are responsible for checking applicable Fees prior to transacting. You
					hereby grant Aravali the right to charge or deduct funds from Your
					custodian wallet hosted on the Platform (or such other cryptocurrency
					wallet, credit card, debit card, online payment account, or other
					payment method, where applicable) for all Fees incurred under this
					Agreement. All Fees will be non-refundable once paid to Aravali
					(including upon any termination or suspension of this Agreement).
					Until paid in full, all past due amounts will bear an additional
					charge of the lesser of 1½% per month or the maximum amount permitted
					under applicable law. If Aravali requires use of collection agencies,
					attorneys, or courts of law for collection of Your account, You will
					be responsible for those expenses. You will be responsible for any and
					all use, sales, and other taxes imposed your access to and use of the
					Platform.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						8. Termination
					</span>
					<br /> This Agreement may be terminated by either party at any time,
					in that party’s sole discretion, upon notice to the other party as
					permitted under this Agreement. Upon termination of this Agreement for
					any reason: (1) all rights granted to You under this Agreement will
					terminate; (2) You will immediately cease all use of and access to the
					Platform and all Services, including Your Content and any Platform
					Content You obtained prior to termination; and (3) Aravali may, in its
					sole discretion, delete Your Account or Your Content at any time. Your
					Content may continue to exist on the Platform and be visible to Users
					after termination of this Agreement unless You actively delete it or
					contact Aravali support and request deletion. If you have
					cryptocurrency assets linked to Your Account, You shall provide
					Aravali a valid bank account number promptly following termination.
					Within a reasonable time after receiving Your bank account number,
					Aravali will convert your cryptocurrency holdings into fiat currency
					and deposit the same in Your designated bank account, less any
					applicable transaction fees incurred. Aravali may request, and You
					shall provide, reasonable proof of ownership of such bank account
					prior to Aravali completing the deposit. Sections 7 (Fees), 8
					(Termination), 10 (Platform Technology), 11 (Ownership), 12
					(Feedback), 14 (Representations and Warranties), 15 (Disclaimers), 16
					(Indemnity), 17 (Limitation on Liability), 18 (Data Privacy), 19 (TCPA
					Consent), 20 (Claims of Infringement), 21 (Force Majeure), 22
					(Arbitration), 23 (Class Action Waiver), 24 (Governing Law and Venue),
					25 (Notices), 26 (Interpretation) and 27 (Additional Terms) will
					survive any expiration or termination of this Agreement.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						9. Suspension
					</span>
					<br /> Without limiting Aravali’s right to terminate this Agreement,
					Aravali may also suspend Your access to Your Account and the Platform
					(including Your Content), with or without notice to You, upon any
					actual, threatened, or suspected breach of this Agreement or
					applicable law or upon any other conduct deemed by Aravali to be
					inappropriate or detrimental to Aravali, the Platform, or any other
					Aravali customer or User.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						10. Platform Technology
					</span>
					<br /> The Platform, and the databases, software, hardware and other
					technology used by or on behalf of Aravali to operate the Platform,
					and the structure, organization, and underlying data, information and
					software code thereof (collectively, the “Technology”), may constitute
					valuable trade secrets of Aravali. You will not, and will not permit
					any third-party to: (1) access or attempt to access the Technology
					except as expressly provided in this Agreement; (2) use the Technology
					in any unlawful manner or in any other manner that could damage,
					disable, overburden or impair the Technology; (3) use automated
					scripts to collect information from or otherwise interact with the
					Technology; (4) alter, modify, reproduce, create derivative works of
					the Technology; (5) distribute, sell, resell, lend, loan, lease,
					license, sublicense or transfer any of Your rights to access or use
					the Technology or otherwise make the Technology available to any
					third-party; (6) reverse engineer, disassemble, decompile, or
					otherwise attempt to derive the method of operation of the Technology;
					(7) attempt to circumvent or overcome any technological protection
					measures intended to restrict access to any portion of the Technology;
					(8) monitor the availability, performance or functionality of the
					Technology; or (9) interfere with the operation or hosting of the
					Technology. Aravali uses reasonable means to protect the security of
					the Platform, but you acknowledge that perfect security on the
					internet is impossible and that, as a result, Your Content may be
					exposed in the event of a breach.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						11. Ownership
					</span>
					<br /> Aravali retains all rights, title and interest, including,
					without limitation, all IPR (as defined below), in and to the
					Technology and any additions, improvements, updates and modifications
					thereto. You receive no ownership interest in or to the Technology and
					You are not granted any right or license to use the Technology itself,
					apart from Your ability to access the Platform under this Agreement.
					The Aravali name, logo and all product and service names associated
					with the Platform are trademarks of Aravali and its licensors and
					providers and You are granted no right or license to use them. For
					purposes of this Agreement, “IPR” means all intellectual property
					rights, proprietary rights, rights of publicity, rights of privacy,
					and any and all other legal rights protecting data, information or
					intangible property throughout the world, including, without
					limitation, any and all copyrights, trademarks, service marks, trade
					secrets, patent rights, moral rights, sui generis rights in databases,
					and contract rights.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						12. Feedback
					</span>
					<br /> You agree that any feedback or ideas You provide to Aravali
					regarding the Platform or its underlying Technology or any suggested
					improvements thereto (together, the “Feedback”) will be the exclusive
					property of Aravali. To the extent You own any rights in the Feedback,
					You hereby agree to, and hereby do, assign all right, title and
					interest in and to the Feedback to Aravali. You agree to perform all
					acts reasonably requested by Aravali to perfect and enforce such
					rights. <br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						13. Availability
					</span>
					<br /> Aravali will use reasonable efforts to ensure that the Platform
					can be accessed by You in accordance with this Agreement; however,
					Aravali does not guarantee that the Platform will be available at all
					times. Aravali will make reasonable efforts to give you notice of
					planned maintenance. You accept the risks associated with the fact
					that you may not always be able to use the Platform or carry out
					transactions using Your Account. <br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						14. Representations and Warranties
					</span>
					<br />{" "}
					<span className={"mt-2 font-medium text-secondary"}>
						14.1. General
					</span>
					<br /> You hereby represent and warrant to Aravali that: (a) You have
					the legal right and authority to enter into this Agreement; (b) this
					Agreement forms a binding legal obligation on Your behalf; and (c) You
					have the legal right and authority to perform Your obligations under
					this Agreement and to grant the rights and licenses described in this
					Agreement.
					<br />
					<span className={"mt-2 font-medium text-secondary"}>
						14.2. Compliance with Laws
					</span>
					<br /> You acknowledge that the Platform is a general-purpose online
					service and is not specifically designed to facilitate compliance with
					any specific law. Your use of the Platform in compliance with any
					specific law, rule, or regulation applicable to You, or other data or
					information You may provide or generate through the Platform is Your
					sole responsibility. Aravali is not responsible for enabling Your
					compliance with any such law or for Your failure to comply. Regardless
					of the jurisdiction in which You use or access the Platform, You
					represent and warrant to Aravali that Your use of and access to the
					Platform, including, without limitation, Your Content and any other
					data or information You may provide or generate through Your use of or
					access to the Platform, will comply with all applicable laws, rules,
					and regulations and will not cause Aravali itself to violate any
					applicable law. The foregoing obligation includes compliance with all
					laws that are applicable to the transmission of data on the internet,
					including, but not limited to, laws governing the transmission of data
					or funds across international boundaries, into prohibited countries,
					and containing financial, technical, and/or personally identifiable
					information. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						14.3. Restricted businesses
					</span>
					<br /> The following categories of businesses and business practices
					are prohibited from using Aravali’s payment services (&quot;Restricted
					Businesses&quot;). Restricted Business categories may be imposed
					through Network Rules or the requirements of our Payment Solutions and
					Financial Services Providers. In certain cases, businesses listed
					below may be eligible for processing with explicit prior approval from
					Aravali. Note, however, that businesses that offer illegal products or
					services are never eligible to use Aravali Services. The types of
					businesses listed in the right column are representative, but not
					exhaustive. If you are uncertain as to whether your business is a
					Restricted Business or have questions about how these requirements
					apply to you, please contact us. By registering with us, you are
					confirming that you will not use the Service to accept payments in
					connection with the following businesses, business activities, or
					business practices, unless you have received prior written approval
					from Aravali.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						Financial and professional services
					</span>
					<br />
					<TableContainer
						className={`${TABLE_CONTAINER_CSS}`}
						component={Paper}
					>
						<Table className={`${TABLE_CSS}`}>
							<TableHead className={`${TABLE_HEAD_CSS}`}>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Category
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Description
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className={`${TABLE_BODY_CSS}`}>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Money and legal services
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Financial institutions, money transmitters and money
										services businesses, check cashing, wire transfers, money
										orders; currency exchanges or dealers; bill-pay services;
										crowdfunding; insurance; bail bonds; collections agencies;
										law firms collecting funds for any purpose other than to pay
										fees owed to the firm for services provided by the firm
										(e.g., firms cannot use Aravali to hold client funds,
										collection or settlement amounts, disputed funds, etc.)
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						IP Infringement, regulated or illegal products and services
					</span>
					<br />
					<TableContainer
						className={`${TABLE_CONTAINER_CSS}`}
						component={Paper}
					>
						<Table className={`${TABLE_CSS}`}>
							<TableHead className={`${TABLE_HEAD_CSS}`}>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Category
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Description
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className={`${TABLE_BODY_CSS}`}>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Intellectual property or proprietary rights infringement
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Sales, distribution, or access to counterfeit music, movies,
										software, or other licensed materials without the
										appropriate authorization from the rights holder; any
										product or service that directly infringes or facilitates
										infringement upon the trademark, patent, copyright, trade
										secrets, or proprietary or privacy rights of any third
										party.
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Counterfeit or unauthorized goods
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Counterfeit goods, aka &ldquo;Replica&rdquo;; unauthorized
										sale or resale of brand name or designer products or
										services; sale of goods or services that are illegally
										imported or exported
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Illegal Gambling
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Lotteries; bidding fee auctions; sports forecasting or odds
										making for a monetary or material prize; fantasy sports
										leagues with cash prizes; internet gaming; contests;
										sweepstakes; games of chance including legal or illegal
										forms of gambling, internet gambling, sweepstakes, and
										contests with a buy-in or cash prize; charity sweepstakes
										and raffles for the explicit purpose of fundraising
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						Unfair, predatory, or deceptive practices
					</span>
					<br />
					<TableContainer
						className={`${TABLE_CONTAINER_CSS}`}
						component={Paper}
					>
						<Table className={`${TABLE_CSS}`}>
							<TableHead className={`${TABLE_HEAD_CSS}`}>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Category</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Description</span>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className={`${TABLE_BODY_CSS}`}>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Get rich quick schemes</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Investment opportunities or other services that promise high
										rewards
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Mug shot publication or pay-to-remove sites
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Platforms that facilitate the publication and removal of
										content (such as mug shots), where the primary purpose of
										posting such content is to cause or raise concerns of
										reputational harm
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>No-value-added services</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Sale or resale of a service without added benefit to the
										buyer; resale of government offerings without authorization
										or added value; sites that we determine in our sole
										discretion to be unfair, deceptive, or predatory towards
										consumers
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						Products or services that are otherwise restricted by our financial
						partners
					</span>
					<br />
					<TableContainer
						className={`${TABLE_CONTAINER_CSS}`}
						component={Paper}
					>
						<Table className={`${TABLE_CSS}`}>
							<TableHead className={`${TABLE_HEAD_CSS}`}>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Category</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Description</span>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className={`${TABLE_BODY_CSS}`}>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Aggregation</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>
											Engaging in any form of licensed or unlicensed aggregation
											of funds owed to third parties, factoring, or other
											activities intended to obfuscate the origin of funds;
											payment facilitation
										</span>
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Drug paraphernalia</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>
											Any equipment designed for making or using drugs, such as
											bongs and vaporizers.
										</span>
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>High risk businesses</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Bankruptcy lawyers; remote technical support; psychic
										services; essay mills; chain letters; door-to-door sales;
										medical benefit packages; telemedicine and telehealth
										services; travel reservation services and clubs; airlines;
										cruises; timeshares; circumvention, jamming and interference
										devices; prepaid phone cards, phone services; telemarketing,
										offering substantial rebates or special incentives as an
										inducement to purchase products or services;
										telecommunications manipulation equipment; forwarding
										brokers; negative response marketing; subscriptions over one
										year; extended warranties; government grants; embassy,
										foreign consulate, or other foreign governments; charities
										without proper registration; credit card and identity theft
										protection; the use of credit to pay for lending services;
										any businesses that we believe poses elevated financial
										risk, legal liability, or violates card network or bank
										policies; any business or organization that a. engages in,
										encourages, promotes or celebrates unlawful violence or
										physical harm to persons or property, or b. engages in,
										encourages, promotes or celebrates unlawful violence toward
										any group based on race, religion, disability, gender,
										sexual orientation, national origin, or any other immutable
										characteristic
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Multi-level marketing</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Pyramid schemes and multi-level marketing
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Pseudo pharmaceuticals</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Nutraceuticals, pseudo-pharmaceuticals, and other products
										that make false health claims that have not been approved or
										verified by the applicable local and/or national regulatory
										body
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										<span>Social media activity</span>
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Sale of Twitter followers, Facebook likes, YouTube views,
										and other forms of social media activity and online traffic
									</TableCell>
								</TableRow>
								<TableRow className={`${TABLE_ROW_CSS}`}>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Substances designed to mimic illegal drugs
									</TableCell>
									<TableCell className={`${TABLE_CELL_CSS}`}>
										Sale of a legal substance that provides the same effect as
										an illegal drug (e.g., salvia, spice&hellip;)
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						15. Disclaimers
					</span>
					<br />
					<span className={"mt-2 font-medium text-secondary"}>
						15.1. No Tax, Legal, Financial, or Investment Advice
					</span>
					<br />
					You should not interpret any Content provided on the Platform as tax,
					legal, financial, or investment advice. We have no special
					relationship with or fiduciary duty to You and Your use of the
					Platform does not create such a relationship. You agree and
					acknowledge that you are solely responsible for conducting legal,
					accounting and other due diligence review on the information posted on
					the Platform.
					<br />
					<span className={"mt-2 font-medium text-secondary"}>
						15.2. Acknowledgement of Cryptocurrency Risk
					</span>
					<br /> Aravali does not endorse or represent the reliability or
					accuracy of any Content distributed through or accessed from the
					Platform, and has not performed any investigation into such Content.
					Aravali shall, therefore, not be liable for any investment decisions
					or transactions made based upon any Content available on the Platform.
					Any reliance upon such Content is at Your sole risk. You further
					acknowledge and agree that any investment or transaction, particularly
					one involving cryptocurrency, includes a high degree of financial
					risk, that You are solely responsible for determining the suitability
					of Your investments and transactions, and that You accept the risks
					associated with such decisions, including the risk of losing the
					entire amount of Your principal. <br />
					<span className={"mt-2 font-medium text-secondary"}>
						15.3. Transaction Disputes
					</span>
					<br /> Aravali’s only involvement in digital transactions on the
					Platform is as a payment processor. If a disagreement occurs between
					You and a User or customer over a transaction (a “Transaction
					Dispute”), You and that User or customer are solely responsible for
					resolving the Transaction Dispute. Aravali disclaims any liability
					arising out of or relating to a Transaction Dispute and will not
					mediate, attempt to resolve, or otherwise become involved in any
					Transaction Dispute.
					<br />
					<span className={"mt-2 font-medium text-secondary"}>
						15.4. General
					</span>
					<br /> THE PLATFORM IS PROVIDED “AS IS” AND “AS AVAILABLE.” Aravali
					AND ITS PROVIDERS DO NOT WARRANT OR GUARANTEE THE ACCURACY,
					COMPLETENESS, AVAILABILITY, ADEQUACY OR CURRENCY OF ANY SERVICES OR
					CONTENT AND DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE EXPRESSED
					OR PROVIDED THROUGH THE PLATFORM. Aravali AND ITS PROVIDERS EXPRESSLY
					DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH
					REGARD TO THE PLATFORM AND THIS AGREEMENT, WHETHER EXPRESS, IMPLIED OR
					STATUTORY, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF FITNESS
					FOR A PARTICULAR PURPOSE, MERCHANTABILITY, TITLE OR NON-INFRINGEMENT.
					Aravali AND ITS PROVIDERS DO NOT WARRANT THE RELIABILITY, ACCURACY,
					INTEGRITY, SECURITY, COMPLETENESS, ADEQUACY OR CURRENCY OF THE
					PLATFORM, AND DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE
					EXPRESSED OR PROVIDED BY USERS OF THE PLATFORM. NO ORAL OR WRITTEN
					INFORMATION OR ADVICE GIVEN BY THE PLATFORM, Aravali OR ITS EMPLOYEES,
					PROVIDERS OR AGENTS SHALL CREATE A WARRANTY OF ANY KIND. Aravali AND
					ITS PROVIDERS SPECIFICALLY DISCLAIM ANY REPRESENTATION OR WARRANTY
					THAT THE PLATFORM WILL MEET YOUR REQUIREMENTS OR OPERATE UNINTERRUPTED
					OR ERROR FREE.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						16. Indemnity
					</span>
					<br /> You hereby agree to indemnify, defend, and hold harmless
					Aravali and its officers, directors, shareholders, affiliates,
					employees, agents, contractors, assigns, Users, customers, providers,
					licensees, and successors in interest (“Indemnified Parties”) from any
					and all claims, losses, liabilities, damages, fees, expenses and costs
					(including attorneys&apos; fees, court costs, damage awards, and
					settlement amounts) that result from any claim or allegation against
					any Indemnified Party arising in any manner from: (1) Your access to
					or use of the Platform; (2) Your Content or Account information or
					other Content You provide through the Platform; and (3) Your breach of
					any representation, warranty, or other provision of this Agreement.
					Aravali will provide You with notice of any such claim or allegation,
					and Aravali will have the right to participate in the defense of any
					such claim at its expense.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						17. Limitation on Liability
					</span>
					<br /> Aravali WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
					SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, UNDER ANY
					THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY OR TORT
					(INCLUDING NEGLIGENCE OR OTHERWISE), ARISING IN CONNECTION WITH OR OUT
					OF THE USE OF THE PLATFORM, EVEN IF Aravali HAS BEEN ADVISED OF THE
					POSSIBILITY OF SUCH DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY LOSS
					OF YOUR CONTENT, DATA, OPPORTUNITY, REVENUES OR PROFITS, BUSINESS
					INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES.
					Aravali’s TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS AGREEMENT
					AND ITS PROVISION OF THE PLATFORM UNDER THIS AGREEMENT, WHETHER IN
					CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED THE FEES YOU ACTUALLY
					PAID TO Aravali FOR USE OF AND ACCESS TO THE PLATFORM IN THE PRECEDING
					TWELVE MONTHS. YOU AGREE THAT Aravali WOULD NOT ENTER INTO THIS
					AGREEMENT WITHOUT THESE LIMITATIONS ON ITS LIABILITY. IN JURISDICTIONS
					WHERE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES
					IS NOT PERMITTED, Aravali’s LIABILITY IS LIMITED TO THE MAXIMUM EXTENT
					PERMITTED BY LAW.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						18. Data Privacy
					</span>
					<br /> You expressly consent to the use and disclosure of Your
					personally identifiable information and Your Content as described in
					the Privacy Policy. Notwithstanding anything in the Privacy Policy,
					Aravali will have the right to collect, extract, compile, synthesize,
					and analyze non-personally identifiable data or information resulting
					from Your access to and use of the Platform. To the extent any such
					non-personally identifiable data or information is collected or
					generated by Aravali, the data and information will be solely owned by
					Aravali and may be used by Aravali for any lawful business purpose
					without a duty of accounting to You, provided that the data and
					information is used only in an aggregated form, without directly
					identifying You or any other entity or natural person as the source
					thereof.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						19. TCPA Consent
					</span>
					<br /> Notwithstanding any current or prior election to opt in or opt
					out of receiving telemarketing calls or SMS messages (including text
					messages) from Aravali or anyone calling on our behalf, You expressly
					consent to be contacted by Aravali and anyone calling on our behalf
					for any and all purposes arising out of or relating this Agreement or
					Your use of the System or Services, at any telephone number, or
					physical or electronic address You provide or at which You may be
					reached. You agree we may contact You in any way, including SMS
					messages (including text messages), calls using prerecorded messages
					or artificial voice, and calls and messages delivered using a
					auto-telephone dialing system or an automatic texting system.
					Automated messages may be played when the telephone is answered,
					whether by You or someone else. In the event that an agent or
					representative calls, he or she may also leave a message on Your
					answering machine, voice mail, or send one via text. You consent to
					receive SMS messages (including text messages), calls and messages
					(including prerecorded and artificial voice and autodialed) from us,
					our agents, representatives, affiliates or anyone calling on our
					behalf at the specific number(s) You have provided to us, or numbers
					we can reasonably associate with Your Account (through skip trace,
					caller ID capture or other means), with information or questions about
					Your Account or use of the System or Services. You certify, warrant
					and represent that the telephone numbers that You have provided to us
					are Your contact numbers. You represent that You are permitted to
					receive calls at each of the telephone numbers You have provided to us
					and agree to promptly alert us whenever You stop using a particular
					telephone number. Your cellular or mobile telephone provider will
					charge You according to the type of plan You carry. You also agree
					that we may contact You by e-mail, using any email address You have
					provided to us or that You provide to us in the future. We may listen
					to and/or record phone calls between You and our representatives
					without notice to You as permitted by applicable law. For example, we
					listen to and record calls for quality monitoring purposes.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						20. Claims of Infringement
					</span>
					<br /> Aravali respects Your copyrights and other intellectual
					property rights and those of other third parties. If You believe in
					good faith that Your copyrighted work has been reproduced on Platform
					without Your authorization in a way that constitutes copyright
					infringement, You may notify us by e-mail to:{" "}
					<a
						className={
							"underline hover:text-secondary-hover visited:text-purple-600"
						}
						href={"mailto:support@aravaliinc.com"}
					>
						support@aravaliinc.com.
					</a>
					Please include the following information in correspondence: (1) the
					identity of the infringed work, and of the allegedly infringing work;
					(2) Your name, address, daytime phone number, and email address, if
					available; (3) a statement that You have a good-faith belief that the
					use of the copyrighted work is not authorized by the owner, his or her
					agent, or the law; (4) a statement that the information in the
					notification is accurate and, under penalty of perjury, that You are
					authorized to act on behalf of the owner; and (5) Your electronic or
					physical signature.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						21. Force Majeure
					</span>
					<br /> Neither party will be responsible for performance of its
					obligations hereunder where delayed or hindered by events beyond its
					reasonable control, including, without limitation, acts of God or any
					governmental body (including regulation, enforcement, controls or
					restrictions on cryptocurrency, blockchain, the System or the
					Service), war or national emergency, riots or insurrection, sabotage,
					embargo, fire, flood, accident, strike or other labor disturbance, or
					interruption of or delay in systems, power or telecommunications under
					third-party control.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						22. Arbitration
					</span>
					<br /> Except as otherwise provided below, the parties will attempt to
					resolve all disputes, controversies, or claims arising under, out of,
					or relating to this Agreement, including the formation, validity,
					binding effect, interpretation, performance, breach or termination, of
					this Agreement and the arbitrability of the issues submitted to
					arbitration hereunder and non-contractual claims relating to this
					Agreement (each, a “Dispute”), in accordance with the procedures set
					forth in this Section. If any Dispute cannot be resolved through
					negotiations between the parties within 5 days of notice from one
					party to the other of the Dispute, such Dispute will be finally
					settled through binding arbitration under the rules of the American
					Arbitration Association (“AAA”) then in effect (the “Rules”). Either
					party may commence the arbitration by delivering a request for
					arbitration as specified in the Rules. The arbitration will be
					conducted before a sole neutral arbitrator selected by agreement of
					the parties. If the parties cannot agree on the appointment of a
					single arbitrator within 30 days (the “Initial Period”) after either
					party to this Agreement delivers a request for arbitration, a neutral
					arbitrator will be selected as provided in the Rules. The arbitration
					will be conducted exclusively in the English language at a site
					specified by Aravali in Pleasanton, California, U.S.A. The award of
					the arbitrator will be the exclusive remedy of the parties for all
					claims, counterclaims, issues or accountings presented or plead to the
					arbitrator. The award of the arbitrators will require payment of the
					costs, fees and expenses incurred by the prevailing party in any such
					arbitration by the non-prevailing party. Judgment upon the award may
					be entered in any court or governmental body having jurisdiction
					thereof. Any additional costs, fees or expenses incurred in enforcing
					the award may be charged against the party that resists its
					enforcement. Notwithstanding the foregoing, you agree that the
					following matters shall not, at the election of Aravali, be subject to
					binding arbitration: (a) any dispute concerning Aravali’s or its
					suppliers or service providers’ IPR; (b) any dispute related to or
					arising from allegations of criminal activity; or (c) any claim for
					injunctive relief. <br />
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						23. Class Action Waiver
					</span>
					<br /> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NO
					ARBITRATION SHALL BE JOINTED TO AN ARBITRATION INVOLVING ANY OTHER
					PARTY SUBJECT TO THIS AGREEMENT, WHETHER THROUGH CLASS ARBITRATION
					PROCEEDINGS OR OTHERWISE. ANY DISPUTE RESOLUTION PROCEEDINGS, WHETHER
					IN ARBITRATION OR COURT, WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL
					BASIS AND NOT IN A CLASS OR REPRESENTATIVE ACTION OR AS A NAMED OR
					UNNAMED MEMBER IN A CLASS, CONSOLIDATED, REPRESENTATIVE OR PRIVATE
					ATTORNEY GENERAL ACTION, UNLESS BOTH YOU AND Aravali SPECIFICALLY
					AGREE TO DO SO IN WRITING FOLLOWING INITIATION OF THE ARBITRATION.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						24. Governing Law and Venue
					</span>
					<br /> You agree that the laws of the State of California, without
					regard to principles of conflict of laws, will govern this Agreement
					and any Dispute, except to the extent governed by federal law.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						25. Notices
					</span>
					<br /> Unless otherwise specified in this Agreement, any notices
					required or allowed under this Agreement will be provided to Aravali
					by postal mail to the following address: [Aravali address for notice].
					Aravali may provide You with any notices required or allowed under
					this Agreement by sending You an email to any email address You
					provide to Aravali in connection with Your Account, provided that in
					the case of any notice applicable both to You and other Users of
					Platform, Aravali may instead provide such notice by posting on
					Platform. Notices provided to Aravali will be deemed given when
					actually received by Aravali. Notice provided to You will be deemed
					given upon posting to Platform or sending via e-mail, unless otherwise
					required by law prohibited or (as to e-mail) the sending party is
					notified that the e-mail address is invalid.
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						26. Interpretation
					</span>
					<br /> Terms used in this Agreement have the definitions given in this
					Agreement or, if not defined in this Agreement, have their plain
					English meaning as commonly interpreted in the United States. To the
					extent any translated version of this Agreement conflicts with the
					English language version, the English language version will control.
					When interpreting this Agreement: (1) any headings are for reference
					purposes only and shall not be used in the construction and
					interpretation of this Agreement; (2) the singular includes the
					plural, and vice versa; (2) “includes”, “including”, “for example”,
					“such as” and similar terms are not words of limitation; (3) no rule
					of construction applies to the disadvantage of a party because that
					party was responsible for the preparation of this Agreement; (4)
					&quot;law&quot; means any foreign, federal, state or local law
					(including common law), statute, standard, code, ordinance, rule,
					regulation, promulgation or any order by any governmental authority;
					and (5) &quot;governmental authority&quot; means any government or
					governmental or regulatory body thereof, or political subdivision
					thereof, whether federal, state, local or foreign, or any agency,
					instrumentality or authority thereof, or any court or arbitrator
					(public or private).
					<br />
					<br />
					<span
						className={
							"text-left text-lg font-medium uppercase text-secondary underline underline-offset-4 md:text-xl"
						}
					>
						27. Additional Terms
					</span>
					<br />
					This Agreement is the complete and exclusive agreement between You and
					Aravali regarding Your access to and use of the Platform. This
					Agreement supersedes any prior agreement or proposal, oral or written,
					and any other communications between You and Aravali relating to Your
					use of the Platform. Except as expressly set forth in this Agreement,
					this Agreement may be amended or modified only by a writing signed by
					both parties. All waivers by Aravali under this Agreement must be in
					writing or later acknowledged by Aravali in writing. Any waiver or
					failure by Aravali to enforce any provision of this Agreement on one
					occasion will not be deemed a waiver by Aravali of any other provision
					or of such provision on any other occasion. If any provision of this
					Agreement is held to be unenforceable, that provision will be removed
					to the extent necessary to comply with the law, replaced by a
					provision that most closely approximates the original intent and
					economic effect of the original to the extent consistent with the law,
					and the remaining provisions will remain in full force. The prevailing
					party in any lawsuit or proceeding arising from or related to this
					Agreement will be entitled to receive its costs, expert witness fees
					and reasonable attorneys’ fees, including costs and fees on appeal.
					You may not assign or transfer either this Agreement or any of Your
					rights or obligations hereunder (in whole or in part and including by
					sale, merger, consolidation, or other operation of law) without the
					prior written approval of Aravali. Any assignment in violation of the
					foregoing will be null and void. Aravali may assign this Agreement to
					any party that assumes Aravali’s obligations hereunder. The parties
					hereto are independent contractors, not agents, employees or employers
					of the other or joint venturers, and neither acquires hereunder any
					right or ability to bind or enter into any obligation on behalf of the
					other.
					<br />
					<br />
				</p>
			</div>
		</div>
	);
};
