import React, { FC } from "react";
import { useTitle } from "../hooks/useTitle";

export const Privacy: FC = () => {
	useTitle("Privacy Policy");

	return (
		<div
			className={
				"flex flex-col gap-y-5  p-5 py-20 bg-white text-gray-700 lg:gap-y-10"
			}
		>
			<div
				className={
					"my-5 flex items-center justify-center text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl"
				}
			>
				<span className={"text-secondary"}>Privacy</span>&nbsp;Policy
			</div>
			<span
				className={
					"flex flex-row items-center justify-center text-lg font-bold uppercase capitalize text-secondary"
				}
			>
				Effective Date: March 31st, 2022
			</span>
			<div
				className={
					"container mx-auto flex max-w-5xl flex-row items-center justify-items-center gap-x-10 pb-8"
				}
			>
				<div className="w-full text-justify text-sm leading-8 tracking-wide text-gray-700 md:text-base">
					<div>
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Our Commitment To Privacy
						</span>
						<br />
						At CoinSpaze, our most important asset is our relationship with you.
						This Privacy Policy is designed to explain how we collect, use, and
						share information gathered through our websites (including
						aravaliinc.com, coinspaze.com, and any other site that links to this
						Privacy Policy), and mobile applications (collectively, the
						“Services”). The terms “Aravali,” “CoinSpaze,” “we,” and “us”
						include Aravali INC, our affiliates and subsidiaries. By using the
						Services, you agree to the terms and conditions of this Privacy
						Policy. Beyond the Privacy Policy, your use of the Service is also
						subject to our Terms of Service as found via the link at the bottom
						of this page.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Information We Collect
						</span>
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Information You Provide Us:
						</span>{" "}
						<br />
						Customer Information: When you sign up to create a CoinSpaze
						account, provide us with information about yourself including:{" "}
						<br />
						<ul className={"list-inside list-disc"}>
							<li>Name</li>
							<li>Address</li>
							<li>E-mail Address</li>
							<li>Phone Number</li>
							<li>Social Security Number</li>
							<li>Date of Birth</li>
							<li>Beneficiary/Beneficiaries Relationship</li>
						</ul>
						<br />
						You may choose to sign in using your Google or Facebook account
						credentials, in which case we collect your Google or Facebook
						account ID and associated email address. If you sign up as an entity
						(e.g., a company, partnership, trust, etc.), you may also need to
						provide us with pertinent information about your entity, including:
						<ul className={"list-inside list-disc"}>
							<li>Entity Name</li>
							<li>Employer Identification Number (EIN)</li>
							<li>Address</li>
							<li>E-mail Address</li>
							<li>Phone Number</li>
							<li>Entity Documents</li>
						</ul>{" "}
						<br />
						If you create an account with another person, you may need to
						provide the above information about that person as well. Beneficiary
						Information: When you create a CoinSpaze account, you also provide
						us information about the beneficiary/beneficiaries of the trust,
						including:
						<ul className={"list-inside list-disc"}>
							<li>Name</li>
							<li>Gender</li>
							<li>Social Security Number</li>
							<li>Date of Birth</li>
							<li>Address</li>
							<li>E-mail Address</li>
							<li>Phone Number</li>
						</ul>{" "}
						<br />
						Financial Information: In order to process your deposits, we may
						collect information about your bank routing numbers, bank account
						numbers, billing address, or credit or debit card. We will also
						maintain maintain information with regards to cryptocurrency
						accounts and balances. If you make a deposit using a credit or debit
						card through the Services, your information will be maintained by a
						third-party merchant bank payment processor and not by us. You may
						also provide information about your financial profile, including
						information about your financial advisor, investment preferences,
						goals, and risk tolerances. Communications: If you contact us
						directly, we may receive additional information about you. For
						example, when you contact our Customer Support Team, we will receive
						your name, email address, phone number, the contents of a message or
						attachments that you may send to us, and other information you
						choose to provide. You may also provide us with contact information
						for other individuals, such as your friends and family, whom you
						would like to invite to join the services or contribute to a trust.
						By submitting personal information relating to other people to us,
						you represent that you have the authority to do so and to permit us
						to use the information in accordance with this Privacy Policy.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Information We Collect Automatically From Use of Services
						</span>{" "}
						<br />
						When you use our Services, we and our third-party service providers
						may collect information from you through automated means, such as
						cookies, web beacons, and web server logs. The information collected
						in this manner includes IP address, browser characteristics, device
						IDs and characteristics, operating system version, language
						preferences, referring URLs, and information about the usage of our
						Services. We may use this information, for example, to ensure that
						the Services function properly, enhance site functionality, analyze
						site usage (for example, to determine how many users have visited
						certain pages or opened messages or newsletters), to assist with our
						advertising and marketing efforts, or to prevent fraud. We work with
						analytics providers such as Google Analytics, which uses cookies and
						similar technologies to collect and analyze information about use of
						the Services and report on activities and trends. This service may
						also collect information regarding the use of other websites, apps
						and online resources. You can learn about Google&apos;s policies by
						going to&nbsp;
						<a
							className={
								"break-words underline hover:text-secondary-hover visited:text-purple-600"
							}
							href="https://policies.google.com/technologies/partner-sites"
						>
							https://policies.google.com/technologies/partner-sites
						</a>
						&nbsp;and opt out of them by downloading the Google Analytics
						opt-out browser add-on, available at&nbsp;
						<a
							className={
								"break-words underline hover:text-secondary-hover visited:text-purple-600"
							}
							href="https://tools.google.com/dlpage/gaoptout"
						>
							https://tools.google.com/dlpage/gaoptout
						</a>
						. If you do not want information collected through the use of
						cookies, there is a simple procedure in most browsers that allows
						you to automatically decline cookies or be given the choice of
						declining or accepting the transfer to your computer of a particular
						cookie (or cookies) from a particular site. You may also wish to
						refer to&nbsp;
						<a
							className={
								"break-words underline hover:text-secondary-hover visited:text-purple-600"
							}
							href="https://www.allaboutcookies.org/manage-cookies/index.html"
						>
							https://www.allaboutcookies.org/manage-cookies/index.html
						</a>
						. If, however, you do not accept cookies, you may experience some
						inconvenience in your use of the Services. You may also learn more
						and manage this site’s use of cookies by clicking &quot;Cookie
						Settings&quot; in the footer.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							How We Use Your Information
						</span>{" "}
						<br />
						To help the government fight the funding of terrorism and money
						laundering activities, Federal law requires all financial
						institutions to obtain, verify, and record information that
						identifies each person who opens an account. What this means for
						you: When you open an account, we will ask for your name, address,
						date of birth, and other information that will allow us to identify
						you. We may also ask to see your driver license or other identifying
						documents.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							We may also use the information we collect:
						</span>
						<ul className={"list-inside list-disc"}>
							<li>
								To operate, maintain, enhance and provide features of the
								services, to provide services and information that you request,
								to respond to comments and questions, and otherwise to provide
								support to users.
							</li>
							<li>
								To understand and analyze the usage trends and preferences of
								our users, to improve the Services, and to develop new products,
								services, features, and functionality.
							</li>
							<li>
								To contact you for administrative and informational purposes,
								and to contact individuals whom you invite to join the service
								or contribute to a trust. This may include providing customer
								service or sending communications, including changes to our
								terms, conditions, and policies, invitations to join the
								service, or requests to contribute to a trust.
							</li>
							<li>
								To personalize your experience on the Services by presenting
								products and offers tailored to you.
							</li>
							<li>
								For marketing purposes, such as developing and providing
								promotional and advertising materials that we think may be
								useful, relevant, valuable or otherwise of interest to you.
							</li>
							<li>
								We may aggregate data collected through the Services and may use
								and disclose it for any purpose.
							</li>
							<li>
								For our business purposes, such as audits, security, compliance
								with applicable laws and regulations, fraud monitoring and
								prevention.
							</li>
							<li>To enforce our Terms of Service or other legal rights.</li>
						</ul>
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							How We Share Your Information
						</span>
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							We disclose information that we collect through the Services to
							third parties in the following circumstances:
						</span>
						<ul className={"list-inside list-disc"}>
							<li>
								To our third-party service providers who provide services such
								as website hosting, payment processing, cryptocurrency trading,
								data analysis, information technology and related infrastructure
								provision, customer service, email delivery, online advertising,
								auditing, and other services.
							</li>
							<li>
								We may use third-party advertising companies to serve
								advertisements regarding goods and services that may be of
								interest to you. To do so, these companies may set and access
								their own cookies, pixel tags, and similar technologies on our
								Services and they may otherwise collect or have access to
								information about you which they may collect over time and
								across different online services. Some of our advertising
								partners are members of the Network Advertising Initiative or
								the Digital Advertising Alliance. If you do not wish to receive
								our personalized ads, please visit their opt-out pages to learn
								about how you may opt out of receiving personalized ads from
								member companies.
							</li>
							<li>
								We may publicly display comments and testimonials we receive
								from users. If you write a comment, send us an email, or other
								communication, we may edit it in our discretion and use it, with
								or without your name, anywhere we desire, including, but not
								limited to, our website(s), press releases, newsletters and
								marketing material.
							</li>
							<li>
								To a potential or actual acquirer, successor, or assignee as
								part of any reorganization, merger, sale, joint venture,
								assignment, transfer or other disposition of all or any portion
								of our business, assets or stock (including in bankruptcy or
								similar proceedings).
							</li>
							<li>
								For marketing purposes, such as developing and providing
								promotional and advertising materials that we think may be
								useful, relevant, valuable or otherwise of interest to you.
							</li>
							<li>
								If required to do so by law or in the good faith belief that
								such action is appropriate: (a) under applicable law, including
								laws outside your country of residence; (b) to comply with legal
								process; (c) to respond to requests from public and government
								authorities, including public and government authorities outside
								your country of residence; (d) to enforce our terms and
								conditions; (e) to protect our operations or those of any of our
								affiliates; (f) to protect our rights, privacy, safety or
								property, and/or that of our affiliates, you or others; and (g)
								to allow us to pursue available remedies or limit the damages
								that we may sustain.
							</li>
							<li>
								We may use and disclose aggregate information that does not
								identify or otherwise relate to an individual for any purpose,
								unless we are prohibited from doing so under applicable law.
							</li>
						</ul>
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Third-Party Services
						</span>{" "}
						<br />
						This Privacy Policy does not address, and we are not responsible
						for, the privacy, information or other practices of any third
						parties, including any third party operating any site or service to
						which the Services link. The inclusion of a link on the Services
						does not imply endorsement of the linked site or service by us or by
						our affiliates.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Security
						</span>{" "}
						<br />
						We use certain physical, organizational, and technical safeguards
						that are designed to maintain the integrity and security of
						information that we collect. Please be aware that no security
						measures are perfect or impenetrable and thus we cannot and do not
						guarantee the security of your data. It is important that you
						maintain the security and control of your account credentials, and
						not share your password with anyone.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Your Choices
						</span>{" "}
						<br />
						From time to time, we send marketing e-mail messages to our users.
						If you no longer want to receive such emails from us on a going
						forward basis, you may opt-out via the “unsubscribe” link provided
						in each such email.&nbsp; You may also learn more and manage this
						site’s use of cookies by clicking this link: Cookie Preferences.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Use of Services by Minors
						</span>{" "}
						<br />
						The Services are not directed to individuals under the age of
						eighteen (18), and we request that they not provide personal
						information through the Services.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							International Cross-Border Data Transfer
						</span>{" "}
						<br />
						CoinSpaze uses servers hosted in the United States. Please note that
						we may be transferring your information outside of your region for
						storage and processing in the United States and around the globe. By
						using the Services you consent to the transfer of information to
						countries outside of your country of residence, including the United
						States, which may have data protection rules that are different from
						those of your country.
						<br />
						<br />
						<span
							className={
								"text-xl font-medium uppercase text-secondary underline underline-offset-4"
							}
						>
							Updates To This Privacy Policy
						</span>{" "}
						<br />
						We may make changes to this Privacy Policy. The “Last Updated” date
						at the top of this page indicates when this Privacy Policy was last
						revised. If we make material changes, we may notify you through the
						Services or by sending you an email or other communication. We
						encourage you to read this Privacy Policy periodically to stay
						up-to-date about our privacy practices. Your use of the Services
						following these changes means that you accept the revised Privacy
						Policy.
					</div>
				</div>
			</div>
		</div>
	);
};
