import { Alert } from "@mui/lab";
import React, { ReactNode } from "react";
import { Code } from "../../components/general/Code";

export const ClientIdInfo = (
	<Alert className={"mx-5 whitespace-pre-line"} severity="warning">
		Contact us at &nbsp;
		<a
			href={"mailto:contact@coinspaze.com"}
			className={"break-words underline"}
		>
			contact@coinspaze.com
		</a>
		&nbsp; to avail your Client Id
	</Alert>
);

export const NpmInstallAravali = (index: string): ReactNode => (
	<div className={"p-5 text-color-text bg-white"}>
		<span>{index}. Link coinspaze react merchant package in to your site</span>
		<Code>
			npm install&nbsp;
			<span className={"text-secondary"}>@aravaliinc</span>
			/react-merchant
		</Code>
	</div>
);

export const CoinSpazeSellerCode = (index: string): ReactNode => (
	<div className={"p-5 text-color-text "}>
		<span>
			{index}. Copy paste following code in to your site wherever you want to
			display CoinSpaze seller button
		</span>
		<Code>
			import &#123;CoinSpazeSeller&#125; from &quot;
			<span className={"text-secondary"}>@aravaliinc</span>
			/react-merchant&quot;;
		</Code>
		<Code>
			&#60;
			<span className={"text-secondary"}>CoinSpazeSeller</span>
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>onSuccess</span>=&#123;(
			<span className={"text-secondary"}>data</span>: any):{" "}
			<span className={"text-secondary"}>void</span>
			&#61;&#62; &#123;YOUR_CODE&#125;&#125;
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>onFailure</span>=&#123;(
			<span className={"text-secondary"}>data</span>: any):{" "}
			<span className={"text-secondary"}>void</span>
			&#61;&#62; &#123;YOUR_CODE&#125;&#125;
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>userEmail</span>
			=&#123;&quot;seller@merchant.com&quot;&#125;
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>clientId</span>
			=&#123;&quot;CLIENT_ID&quot;&#125; <br />
			/&#62;
		</Code>
	</div>
);

export const CoinSpazeBuyerCode = (index: string): ReactNode => (
	<div className={"p-5 text-color-text"}>
		<span>
			{index}. Copy paste following code in to your site wherever you want to
			display CoinSpaze buyer or seller button
		</span>
		<Code>
			import &#123;CoinSpazeBuyer&#125; from &quot;
			<span className={"text-secondary"}>@aravaliinc</span>
			/react-merchant&quot;;
		</Code>
		<Code>
			&#60;
			<span className={"text-secondary"}>CoinSpazeBuyer</span>
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>onSuccess</span>=&#123;(
			<span className={"text-secondary"}>data</span>: any):{" "}
			<span className={"text-secondary"}>void</span>
			&#61;&#62; &#123;YOUR_CODE&#125;&#125;
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>onFailure</span>=&#123;(
			<span className={"text-secondary"}>data</span>: any):{" "}
			<span className={"text-secondary"}>void</span>
			&#61;&#62; &#123;YOUR_CODE&#125;&#125;
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>userEmail</span>
			=&#123;&quot;REPLACE_THIS_WITH_BUYER_EMAIL&quot;&#125;
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>order</span>
			=&#123;&quot;REPLACE_THIS_WITH_ORDER_DATA&quot;&#125;
			<br />
			<span className="ml-8" />
			<span className={"text-secondary"}>clientId</span>
			=&#123;&quot;REPLACE_THIS_WITH_MARKETPLACE_CLIENT_ID&quot;&#125; <br />
			/&#62;
		</Code>
	</div>
);
