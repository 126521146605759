import React, { FC, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ReactComponent as ETH } from "../assets/logos/SVGs/Ether.svg";
import { ReactComponent as BTC } from "../assets/logos/SVGs/btcB.svg";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const style = {
	position: "relative",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	background:
		"linear-gradient(45deg, #500c5d, #580d6c, #600e7c, #67118c, #6e159d, #7319af, #771fc2, #7a25d5)",
	overflow: "hidden",
	boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
	backdropFilter: "blur(25px)",
	"-webkit-backdrop-filter": "blur(5px)",
	outline: "none",
};

export const PopUpComponent: FC = () => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose: any = (event: Event, reason: string) => {
		if (reason && reason === "backdropClick" && "escapeKeyDown") return;
		setOpen(false);
	};
	useEffect(() => {
		setTimeout(handleOpen, 2000);
	}, []);
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={style}
					className="max-h-[100%] max-w-[90%] md:h-[70%] md:w-[35%]"
				>
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
					<div className="w-48 h-48 absolute top-[-2%] right-[-14%] md:top-[-1%] md:right-[-19%] object-cover rotate-45 overflow-hidden">
						<ETH />
					</div>
					<Box className="flex justify-center">
						<Typography className="text-6xl mt-4 md:mt-6 font-bold text-white text-center font-sans">
							Win up to $250 in Bitcoin
						</Typography>
					</Box>
					<Typography className="text-white text-2xl mt-4 mx-16 text-center font-semibold font-sans ">
						Earn $5 when you register* with us and another $5 when each of your
						referrals registers*
					</Typography>
					<Box className="w-64 h-64 absolute  bottom-[-30%] left-[-35%] md:bottom-[-20%] md:left-[-20%] object-cover rotate-45 overflow-hidden">
						<BTC />
					</Box>
					<div className="text-center mt-4 mb-7">
						<Button
							className="bg-black text-white px-8 py-2 rounded-3xl "
							href={"https://app.coinspaze.com"}
						>
							Click here to Continue
						</Button>
					</div>
					<Typography className="text-white absolute bottom-[0%] right-2 text-xs mb-2 ">
						*Create an account and get KYC approved with CoinSpaze
					</Typography>
				</Box>
			</Modal>
		</div>
	);
};
