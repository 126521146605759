import React, { FC, ReactNode } from "react";
import {
	faArrowAltCircleDown,
	faArrowAltCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as BTC } from "../../assets/logos/SVGs/btc.svg";
import { ReactComponent as ETH } from "../../assets/logos/SVGs/eth.svg";
import { ReactComponent as LTC } from "../../assets/logos/SVGs/ltc.svg";
import { ReactComponent as BTCGraph } from "../../assets/logos/SVGs/btcGraph.svg";
import { ReactComponent as ETHGraph } from "../../assets/logos/SVGs/ethGraph.svg";
import { ReactComponent as LTCGraph } from "../../assets/logos/SVGs/ltcGraph.svg";

const PredictionElement: FC<PredictionElementType> = ({
	title,
	icon,
	change,
	graph,
	upOrDown = "up",
}) => {
	return (
		<div
			className={`flex select-none ${
				upOrDown === "up" ? "flex-col-reverse " : "flex-col-reverse"
			} gap-y-2`}
		>
			<div
				className={`flex flex-row items-center justify-center gap-x-2 text-lg font-semibold uppercase tracking-widest ${
					upOrDown === "up" ? "text-white" : "text-secondary"
				}`}
			>
				{/* {upOrDown === "up" ? "go up" : "go down"} */}
				{upOrDown === "up" ? (
					<FontAwesomeIcon
						icon={faArrowAltCircleUp}
						className={"animate-pulse"}
					/>
				) : (
					<FontAwesomeIcon
						icon={faArrowAltCircleDown}
						className={"animate-pulse text-white"}
					/>
				)}
			</div>
			<div
				className={`flex flex-row items-center gap-x-5 rounded-lg bg-highlight p-3 lg:p-6`}
			>
				{icon}
				<div className={"flex flex-col justify-center"}>
					<p className={"uppercase text-white"}>{title}</p>
					<p
						className={`${
							upOrDown === "up" ? "text-[#00FF00]" : "text-[#f35146]"
						} text-sm`}
					>
						{change}
					</p>
				</div>
				{graph}
			</div>
		</div>
	);
};

const predictionArray: PredictionElementType[] = [
	{
		title: "BTC",
		upOrDown: "up",
		change: "+ 2.6%",
		graph: <BTCGraph className={"max-w-[80px] lg:w-32"} />,
		icon: <BTC className={"w-14 lg:h-auto lg:w-auto"} />,
	},
	{
		title: "ETH",
		upOrDown: "down",
		change: "- 0.58%",
		graph: <ETHGraph className={"max-w-[80px] lg:w-32"} />,
		icon: <ETH className={"w-14 lg:h-auto lg:w-auto"} />,
	},
	{
		title: "LTC",
		upOrDown: "up",
		change: "+ 0.65%",
		graph: <LTCGraph className={"max-w-[80px] lg:w-32"} />,
		icon: <LTC className={"w-14 lg:h-auto lg:w-auto"} />,
	},
];

export const Predictions: FC = () => {
	return (
		<section className={"scroll-mt-12 p-3 text-gray-700 lg:pt-20 lg:px-20"}>
			<div
				className={
					"my-5 text-center text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl"
				}
			>
				<span className={"text-secondary"}>Track</span>&nbsp;changes to sell or
				buy
			</div>
			<div
				className={
					"container mx-auto flex flex-col items-center justify-center gap-8 py-10 lg:flex-row"
				}
			>
				{predictionArray.map(({ title, icon, change, graph, upOrDown }) => (
					<PredictionElement
						key={title}
						title={title}
						icon={icon}
						change={change}
						graph={graph}
						upOrDown={upOrDown}
					/>
				))}
			</div>
		</section>
	);
};

type PredictionElementType = {
	title: string;
	icon: ReactNode;
	change: string;
	graph: ReactNode;
	upOrDown?: "up" | "down";
};
