import React, { ReactElement, useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export const Widget = (): ReactElement => {
	const tawkMessengerRef = useRef();

	return (
		<div>
			<TawkMessengerReact
				propertyId={process.env.REACT_APP_PROPERTY_ID}
				widgetId={process.env.REACT_APP_WIDGET_ID}
				ref={tawkMessengerRef}
			/>
		</div>
	);
};
