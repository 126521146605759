import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";

export const Footer: FC = () => {
	const navigate = useNavigate();
	return (
		<footer id={"contact"} className={"mt-auto bg-white"}>
			<div
				className={
					"border-t border-gray-300 flex w-full flex-col items-center justify-between gap-x-20 px-5 lg:px-20 lg:gap-x-20 gap-y-3 py-5 lg:flex-row container mx-auto max-w-6xl"
				}
			>
				<Typography variant={"body2"}>
					&#169; 2023 Aravali, Inc. All Rights Reserved
				</Typography>
				<span className={"flex flex-row items-center"}>
					<Typography
						component={Button}
						className={"text-gray-500 capitalize"}
						variant={"body2"}
						onClick={(): void => navigate("/privacy-policy")}
					>
						Privacy
					</Typography>
					&#8226;
					<Typography
						component={Button}
						className={"text-gray-500 capitalize"}
						variant={"body2"}
						onClick={(): void => navigate("/terms-and-conditions")}
					>
						Terms
					</Typography>
					&#8226;
					<Typography
						component={Button}
						className={"text-gray-500 capitalize"}
						variant={"body2"}
						href={"mailto:contact@coinspaze.com"}
					>
						Contact
					</Typography>
				</span>
			</div>
		</footer>
	);
};
