import React, { FC, ReactNode } from "react";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Zero from "../../assets/logos/PNGs/Zero.png";
import BTC from "../../assets/flow/3BTC.png";
import Future from "../../assets/flow/future.png";
import { Link } from "react-router-dom";

const Feature: FC<FeatureType> = ({
	title,
	buttonLabel,
	description,
	indexTitle,
	imageDirection = "right",
	img,
	imgNode,
}) => {
	return (
		<div className={"container relative mx-auto max-w-5xl justify-between"}>
			<div
				className={`flex items-center md:gap-x-4 lg:gap-x-10 ${
					imageDirection === "right"
						? "flex-col lg:flex-row"
						: "flex-col lg:flex-row-reverse"
				} gap-4 md:gap-0 md:p-8 lg:px-16`}
			>
				{imgNode ? (
					imgNode
				) : (
					<img
						src={img}
						alt={""}
						className={"w-auto max-w-xs md:h-64 lg:h-80"}
					/>
				)}
				<div className={"relative flex flex-col gap-y-8 p-8 md:p-16 lg:p-0"}>
					<div className={"z-10 flex flex-col gap-y-8"}>
						<div
							className={
								"flex md:visible w-full flex-row items-center md:gap-x-0 font-bold uppercase text-secondary"
							}
						>
							<div className={"h-0.5 w-16 bg-black"} />
							{indexTitle}
						</div>
						<p className={"font-[600] text-3xl md:text-4xl lg:text-5xl"}>
							{title}
						</p>
						<p className={""}>{description}</p>
						{buttonLabel && (
							<div>
								<Button
									variant={"contained"}
									className={" text-secondary"}
									endIcon={<FontAwesomeIcon icon={faArrowRight} />}
									color={"secondary"}
									sx={{ textTransform: "none", borderRadius: 4 }}
									href={"https://app.coinspaze.com"}
								>
									{buttonLabel}
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const featuresArray: FeatureType[] = [
	{
		title: "Next Era Payments",
		buttonLabel: "Sign Up",
		img: Zero,
		indexTitle: "Get Started",
		description:
			"Bored of those time-wasting and expensive sites to handle your crypto wallet?\n" +
			"We have the ideal solution tailored just for you.\n" +
			"Optimizing your time without wasting it in long waiting periods to withdraw your crypto won’t be a problem anymore!\n" +
			"With CoinSpaze you will gain a fast and trustful friend that will always be at your side.\n" +
			"Just a couple of clicks and here we go: funds in hands.",
	},
	{
		title: "Simplified Trading",
		buttonLabel: "Sign Up",
		indexTitle: "Your money is your money",
		img: BTC,
		description: (
			<>
				Enjoy a flat fee service and save your cash to get on with what you do
				best: <b>investing in crypto</b>.
				<br />
				CoinSpaze allows you to trade for a&nbsp;
				<Link
					to={"/pricing"}
					className={"underline hover:text-secondary-hover"}
				>
					low fee
				</Link>
				. Crypto transfers within the platform are <b>FREE</b>.
				<br />
				Everything you always wanted to manage your wallet in just one
				user-friendly place you can use wherever you may be.
				<br />
				Anyone can become an expert with us, especially you!
				<br />
			</>
		),
	},
	{
		title: "Invest in your future",
		buttonLabel: "Sign Up",
		indexTitle: "be one step ahead",
		img: Future,
		description:
			"We believe in crypto’s future as it's becoming the main engine of the internet financial system.\n" +
			"This is why we’re here; this is why you are here.\n" +
			"Your future is in your hands, so what are you waiting for?\n" +
			"Do it better, do it faster, do it with CoinSpaze.",
	},
];

export const Features: FC = () => {
	return (
		<section
			className={"h-fit scroll-mt-10  p-3 text-gray-700 lg:px-20"}
			id={"features"}
		>
			{featuresArray.map(
				(
					{ title, indexTitle, buttonLabel, img, description, imgNode },
					index
				) => (
					<Feature
						key={index}
						title={title}
						description={description}
						indexTitle={indexTitle}
						imageDirection={index % 2 === 0 ? "left" : "right"}
						buttonLabel={buttonLabel}
						img={img}
						imgNode={imgNode}
					/>
				)
			)}
		</section>
	);
};

type FeatureType = {
	title: string;
	description: ReactNode;
	buttonLabel?: string;
	indexTitle: string;
	imageDirection?: "right" | "left";
	img?: string;
	buttonHref?: string;
	imgNode?: ReactNode;
};
