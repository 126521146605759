import React, { FC } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};
const validationSchema = yup.object({
	email: yup.string().email().required(),
	business: yup.string().required(),
	phone: yup.string().min(10).max(10).required(),
	person: yup.string().required(),
});

export const ModalComponent: FC<ModalProps> = ({ prop, details }) => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const formik = useFormik({
		initialValues: {
			business: "",
			person: "",
			phone: "",
			email: "",
			currentProvider: "",
			Description: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});
	const handleClose = (event: any, reason: any) => {
		if (reason !== "backdropClick") {
			setOpen(false);
		}
	};
	{
		console.log(prop);
	}
	return (
		<div id={"contact"} className={"bg-primary pt-24 text-white"}>
			<div className={"container mx-auto flex flex-col gap-y-4 pt-5"}>
				<div
					className={
						"flex flex-col items-center justify-center gap-y-10 gap-x-20"
					}
				>
					<iframe
						src="https://docs.google.com/forms/d/e/1FAIpQLSeXYemLtx1fVbFl7BsTCr2Jogf9DWQCnqHfMSQYxjVHbwIfBg/viewform?embedded=true"
						width="100%"
						height="1200"
						frameBorder="0"
						marginHeight={0}
						marginWidth={0}
					>
						Loading…
					</iframe>
				</div>
			</div>
		</div>
	);
};

interface ModalProps {
	prop: string;
	details: string;
}
