import React, { FC } from "react";
import Dashboard from "../../assets/flow/dashboard.png";
import { useTitle } from "../../hooks/useTitle";

export const About: FC = () => {
	useTitle("About");

	return (
		<div
			id={"advantage"}
			className={
				"flex scroll-mt-12 flex-col gap-y-5 px-5 pt-12 bg-white text-white lg:gap-y-10 lg:px-20 lg:pt-20"
			}
		>
			{/* <div
				className={
					" mb-5 mt-16 flex items-center justify-center font-bentham text-3xl  font-semibold tracking-wide md:text-4xl lg:text-5xl"
				}
				data-show={"down"}
				data-delay={0}
			>
				<span className={"text-secondary"}>About</span>&nbsp;Us
			</div> */}
			<div
				className={
					"container mt-12 mx-auto flex max-w-5xl flex-row items-center justify-items-center gap-x-10 pb-8"
				}
			>
				<img
					src={Dashboard}
					alt={""}
					className={"hidden w-auto max-w-xs md:block md:h-72 lg:h-96"}
				/>
				<p
					className={
						"w-full text-center text-base text-gray-700 tracking-wide md:w-2/3 md:text-left"
					}
				>
					CoinSpaze is disrupting digital payments by allowing merchants and
					consumers to transact with ease using cryptocurrencies and FIAT.
					<br />
					<br />
					We’ve applied the idea of free and instant crypto transfers to bridge
					the divide between users of traditional currency and various different
					cryptocurrencies, by enabling users to receive payment in the currency
					of their choice, regardless of the sent payment’s currency. With
					CoinSpaze you can pay and receive in the currency of your choice with
					a single click and instant settlement.
					<br />
					<br />
					Crypto is en route to become a major force in financial transaction
					processing, and with every new user, the freedom granted by CoinSpaze
					greatly increases the liquidity of cryptocurrencies, empowering us to
					bring&nbsp;
					<span className={"font-bold text-secondary"}>
						Crypto to the Masses
					</span>
					.
				</p>
			</div>
		</div>
	);
};
