import React, { FC } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import {
	faFacebook,
	faInstagram,
	faLinkedinIn,
	faProductHunt,
	faTiktok,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

export const Extras: FC = () => {
	return (
		<div className={"bg-white lg:pb-16"}>
			<div className={"px-5 lg:px-20"}>
				<div
					className={
						"border-t border-gray-300 pt-5 lg:pt-12 container lg:px-20 mx-auto w-full flex flex-col sm:flex-row gap-10 justify-between max-w-6xl flex-wrap"
					}
				>
					<div className={"flex flex-col items-start gap-2 mr-auto sm:mr-auto"}>
						<Typography
							gutterBottom={true}
							marginBottom={2}
							className={"text-gray-500"}
							variant={"button"}
						>
							COMPANY
						</Typography>
						<NavLink to={"/about"}>
							<Typography
								className={"text-gray-800 hover:text-secondary-hover"}
								variant={"subtitle2"}
							>
								About Us
							</Typography>
						</NavLink>
						<NavLink to={"/partners"}>
							<Typography
								className={"text-gray-800 hover:text-secondary-hover"}
								variant={"subtitle2"}
							>
								Partners
							</Typography>
						</NavLink>
						<NavLink to={"/contact"}>
							<Typography
								className={"text-gray-800 hover:text-secondary-hover"}
								variant={"subtitle2"}
							>
								Contact
							</Typography>
						</NavLink>
					</div>
					<div className={"flex flex-col items-start gap-2 mr-auto sm:mx-auto"}>
						<Typography
							gutterBottom={true}
							marginBottom={2}
							className={"text-gray-500"}
							variant={"button"}
						>
							GET STARTED
						</Typography>
						<a href={"https://app.coinspaze.com"} rel="noreferrer">
							<Typography
								className={"text-gray-800 hover:text-secondary-hover"}
								variant={"subtitle2"}
							>
								Log In
							</Typography>
						</a>
						<a href={"https://app.coinspaze.com"} rel="noreferrer">
							<Typography
								className={"text-gray-800 hover:text-secondary-hover"}
								variant={"subtitle2"}
							>
								Sign Up
							</Typography>
						</a>
					</div>
					<div className={"flex flex-col items-start gap-2 mr-auto sm:ml-auto"}>
						<Typography
							gutterBottom={true}
							marginBottom={2}
							className={"text-gray-500"}
							variant={"button"}
						>
							RESOURCES
						</Typography>
						<a
							href={"https://medium.com/@coinspaze"}
							target={"_blank"}
							rel="noreferrer"
						>
							<Typography
								className={"text-gray-800 hover:text-secondary-hover"}
								variant={"subtitle2"}
							>
								Blogs
							</Typography>
						</a>
					</div>
				</div>
				<div
					className={
						"container mx-auto w-full flex flex-col w-full text-2xl mt-8 max-w-6xl lg:px-20"
					}
				>
					<Typography
						gutterBottom={true}
						className={"text-gray-500"}
						variant={"button"}
					>
						SOCIAL
					</Typography>
					<div className={"flex flex-row gap-x-5 overflow-x-auto"}>
						<Tooltip title={"LinkedIn"}>
							<IconButton
								type={"icon"}
								href={"https://www.linkedin.com/company/coinspaze/"}
								target={"_blank"}
							>
								<FontAwesomeIcon icon={faLinkedinIn} />
							</IconButton>
						</Tooltip>
						<Tooltip title={"Instagram"}>
							<IconButton
								type={"icon"}
								href={"https://www.instagram.com/coinspaze/"}
								target={"_blank"}
							>
								<FontAwesomeIcon icon={faInstagram} />
							</IconButton>
						</Tooltip>
						<Tooltip title={"TikTok"}>
							<IconButton
								type={"icon"}
								href={"https://www.tiktok.com/@coinspaze/"}
								target={"_blank"}
							>
								<FontAwesomeIcon icon={faTiktok} />
							</IconButton>
						</Tooltip>
						<Tooltip title={"Facebook"}>
							<IconButton
								type={"icon"}
								href={"https://www.facebook.com/CoinSpaze-104152129090642/"}
								target={"_blank"}
							>
								<FontAwesomeIcon icon={faFacebook} />
							</IconButton>
						</Tooltip>
						<Tooltip title={"Twitter"}>
							<IconButton
								type={"icon"}
								href={"https://twitter.com/coinspaze"}
								target={"_blank"}
							>
								<FontAwesomeIcon icon={faTwitter} />
							</IconButton>
						</Tooltip>
						<Tooltip title={"Product Hunt"}>
							<IconButton
								type={"icon"}
								href={"https://www.producthunt.com/products/coinspaze"}
								target={"_blank"}
							>
								<FontAwesomeIcon icon={faProductHunt} />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	);
};
