import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useTitle } from "../hooks/useTitle";

export const NotFound: FC = () => {
	useTitle("Not Found");

	return (
		<div
			className={
				"flex h-full flex-col items-center justify-center gap-y-5 bg-primary p-5 py-24 text-white lg:gap-y-10 lg:p-24"
			}
		>
			<FontAwesomeIcon
				className={"text-7xl text-secondary"}
				icon={faTriangleExclamation}
			/>
			<div
				className={
					"mb-5 flex items-center justify-center  text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl"
				}
			>
				<span className={"text-secondary"}>Page</span>&nbsp;Not&nbsp;
				<span className={"text-secondary"}>Found</span>
			</div>
		</div>
	);
};
