import React, { FC } from "react";
import { useTitle } from "../hooks/useTitle";

export const Contact: FC = () => {
	useTitle("Contact");

	return (
		<div id={"contact"} className={"pt-24 bg-white text-gray-700"}>
			<div className={"container mx-auto flex flex-col gap-y-4 p-10"}>
				<div className={"flex flex-col gap-y-5 text-center"}>
					{/*<p
						className={
							"font-bentham text-5xl text-3xl font-semibold tracking-wide md:text-4xl lg:text-5xl"
						}
					>
						<span className={"text-secondary"}>Contact</span>&nbsp;Us
					</p>*/}
					<p className="text-justify">
						CoinSpaze is a San Francisco Bay Area, USA, based startup, founded
						by technology and business veterans with ecommerce, payments and
						fintech expertise. We believe that crypto payments are the future
						that would transform retail and online commerce through virtual
						currency. For partnerships, integration or sales, fill out the below
						form and we will get back to you.
					</p>
					<div
						className={
							"flex flex-col items-center justify-center gap-y-10 gap-x-20"
						}
					>
						<iframe
							src="https://docs.google.com/forms/d/e/1FAIpQLSeXYemLtx1fVbFl7BsTCr2Jogf9DWQCnqHfMSQYxjVHbwIfBg/viewform?embedded=true"
							width="100%"
							height="1200"
							frameBorder="0"
							marginHeight={0}
							marginWidth={0}
						>
							Loading…
						</iframe>
					</div>
				</div>
			</div>
		</div>
	);
};

type formType = {
	name?: string;
	email?: string;
	description?: string;
};
