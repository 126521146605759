import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { TAG_TYPE } from "./enums";

export const navigationData: NavigationType[] = [
	{
		id: "1",
		title: "Company",
		icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
		subList: [
			{
				id: "1-1",
				title: "About Us",
				href: "/about",
				icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
			},
			{
				id: "1-2",
				title: "Partners",
				href: "/partners",
				icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
			},
			{
				id: "1-3",
				title: "Contact",
				href: "/contact",
				icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
			},
		],
	},
	{
		id: "2",
		title: "Merchants",
		href: "/merchants",
		icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
	},
	{
		id: "3",
		title: "Creators",
		href: "/creators",
		icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
	},
	{
		id: "4",
		title: "Personal",
		href: "/shoppers",
		icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
	},
	// {
	// 	id: "5",
	// 	title: "Partners",
	// 	href: "/partners",
	// 	icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
	// },
	// {
	// 	id: "6",
	// 	title: "Contact",
	// 	href: "/contact",
	// 	icon: <FontAwesomeIcon className={"text-white"} icon={faLaptopCode} />,
	// },
];

export type NavigationType = {
	id: string;
	title: string;
	href?: string;
	subList?: NavigationType[];
	tag?: TAG_TYPE;
	disabled?: boolean;
	icon?: ReactNode;
};
